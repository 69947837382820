<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">항목추가</p>
        <table class="w100">
          <col style="width:30%">
          <tr>
            <th>날짜</th>
            <td>
              <VueDatePicker v-model="date[0]" locale="ko" format="yyyy-MM-dd" :enable-time-picker="false" cancelText="취소" selectText="확인" class="vue-datapicker w100" />            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td><input type="text" class="w100"></td>
          </tr>
          <tr>
            <th>사용금액</th>
            <td><input type="number" class="w100"></td>
          </tr>
        </table>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  props: ['popupId'],
  components: { VueDatePicker },
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      date: [null],
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:45rem; 
      table {
        td {
          padding: 1rem 2rem;
          input {
            padding:0 1rem;
          }
        }
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>