<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">강의소개</p>
        <div class="scroll-box">
          <dl>
            <dt>교과목 개요</dt>
            <dd><pre>{{ popupData[0].data.courseSummary }}</pre></dd>

            <dt>수업목표</dt>
            <dd><pre>{{ popupData[0].data.goal }}</pre></dd>

            <dt>수업규정 또는 안내사항</dt>
            <dd><pre>{{ popupData[0].data.evaluationArticle }}</pre></dd>
          </dl>
        </div>
      </div>   
      <div class="pop-btn tc mgt20">
        <button @click="closePopup" type="button">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
export default {
  props: ['popupId', 'popupData'],
  setup() {
    const { useMobile } = getCurrentInstance().appContext.config.globalProperties.$common;
    const { isMobile } = useMobile();
    return {
      isMobile,
    };
  },
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      dt {
        padding-bottom: 1rem;
        font-size: 2rem; 
        font-weight: 500;
        &::before {
          content:'';
          display: inline-block;
          width: .5rem;
          height: .5rem;
          vertical-align: top;
          margin: .7rem .6rem 0 0;
          background: $cl2;
        }
      }
      dd {
        margin-left: 1.2rem;
        padding-bottom:2rem;
        line-height: 1.5;
      }
    }        
  }  
}
</style>