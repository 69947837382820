<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">마이크로디그리 이수신청</p>
        <p class="t2 mgt20">교과목 이수 목록</p>        
        <div class="scroll-box">
          <div class="ta">          
            <table class="w100 tc mgt10">
              <colgroup>
                <col style="width: 8%;">
                <col style="width: 10%;">
                <col style="width: 8%;">
                <col style="width: 30%;">
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th>순번</th>
                  <th>학년도</th>
                  <th>학기</th>
                  <th>교과목 정보</th>
                  <th>마이크로디그리</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(course, index) in courseList" :key="course.subjctCd">
                  <td>{{ index+1 }}</td>
                  <td>{{ course.syy }}</td>
                  <td>{{ getSemesterName(course.smtCd) }}</td>
                  <td>
                    <p>[ <strong>{{ course.subjtCd }}</strong> ]</p>
                    {{ course.subjtNm }}
                  </td>
                  <td>
                    <p v-for="dgr in course.dgr_nm_list" :key="dgr">
                        <span v-if="dgr !== '추가사용(차세대통신 중급)' && dgr !== '추가사용(차세대통신 고급)'">
                          {{ dgr }}
                        </span>
                    </p>
                  </td>
                </tr>

                <tr v-if="courseList.length === 0">
                  <td colspan="5" class="empty">조회된 강의가 없습니다</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ea mgt10">
            <div class="box">
              <p class="t3">
                이미 신청완료한 마이크로디그리는 신규로 신청할 수 없습니다.<br>
                <small>2개 이상의 마이크로디그리 신청을 원하실 경우, 담당자에게 문의주세요.</small>
              </p>
              <div class="mgt20">
                <!-- 신청할 수 있는 마이크로디그리 목록에서 추가사용(어쩌고) 류는 제외해야함 -->
                <label v-for="(option, index) in filteredOptions" :key="index" class="rad">
                  <input type="radio" :value="{seqc: option.seqc, dgrNm: option.dgrNm}" v-model="selectedOption"/>
                  <i></i>
                  <span>
                    {{ option.dgrNm }}
                  </span>
                </label>
              </div>          
            </div>
          </div>
        </div>    
      </div>          
      
      <div class="pop-btn tc">
        <button @click.prevent="checkAdd()" type="button" class="ty2">확인</button>
        <!-- <button @click="closePopup" type="button" class="ty2">확인</button> -->
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { onUnmounted } from 'vue';
import { getDigitalBadgeApplyAPI, updateDigitalBadgeApplyAPI } from '@/api'
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();
const userSeqc = ref('')
userSeqc.value = cookies.get('userInfo').userSeq

const props = defineProps(['popupId']);
const store = useStore();
const isTransitioning = ref(false);
const isPopupOpen = computed(() => store.getters.getPopupById(props.popupId) !== undefined);

// 마이크로디그리 목록
const options = ref([]);
const selectedOption = ref('');
const selectedSubject = ref([]);

// 교과목록
const courseList = ref([]);
const grouped = reactive({})

watch(() => isPopupOpen.value, (newVal) => {
  initializeData();
  if(newVal) {
    fetchData();
  }
})

// 객체 초기화 함수로 혹시모를 중복 데이터 방지
function initializeData() {
  selectedSubject.value = [];
  filteredOptions.value = [];
  options.value = [];
  courseList.value = [];
  clearGrouped();
}

const fetchData = async () => {
  const response = await getDigitalBadgeApplyAPI(userSeqc.value)
  if (response.data.microDegrees.length !== 0) {
    options.value = response.data.microDegrees;
    filteringOptions();
    // selectedOption.value = {seqc: filteredOptions.value[0]['seqc'], dgrNm: filteredOptions.value[0]['dgrNm']}\
    // 초기값 없애기
  }
  courseList.value = response.data.courseList;
  settingData();
  courseList.value = Object.values(grouped);
}

// 마이크로디그리 리스트
const filteredOptions = ref([])
const filteringOptions = async () => {
  options.value.filter(item => {
    if(![15, 16, 17].includes(item.seqc)){
      // 종합설계군, 추가사용(차세대통신 중급), 추가사용(차세대통신 고급)
      filteredOptions.value.push(item);
    }
  })
}

// 이수 과목 리스트 
function settingData(){
  clearGrouped();
  Object.values(courseList.value).forEach((item) => {
    if (!grouped[item.subjtCd]){
      grouped[item.subjtCd] = {
          ...item,
          dgr_nm_list: [item.dgrNm]
        };
    } else {
      grouped[item.subjtCd].dgr_nm_list.push(item.dgrNm);
    }
  })
}

function clearGrouped() {
  Object.keys(grouped).forEach(key => delete grouped[key]);
}

const getSemesterName = (smt_cd) => {
  const semesterMap = {
    10: "1학기",
    11: "여름계절학기",
    20: "2학기",
    12: "겨울계절학기",
  };
  return semesterMap[smt_cd] || "학기 정보 없음";
};

// 확인 누르면 selectedOption 디그리가 포함된 과목들을 보내줘야함 => subjtCds -> DTO가 아니어야될 것 같은데? 
// List로 보내줘야할 것으로 보임
function checkAdd() {
  const requestData = [];
  if (selectedOption.value !== null && selectedOption.value !== '' && selectedOption.value !== undefined){
    let additionalList = []; 
    let additionalSeqc = 0;
    Object.values(grouped).forEach(item => {
      item.dgr_nm_list.forEach(dgrNm => {
        if (selectedOption.value['dgrNm'] === dgrNm) {
          selectedSubject.value.push(item.subjtCd); // 이수과목 과목코드(subjtCd) 보내주기
          requestData.push({
            subjtCds: selectedSubject.value,
            mdIssueDgrSeqc: selectedOption.value['seqc'],
            userSeqc: userSeqc.value
          });
        }

        // '차세대통신 중급' 또는 '차세대통신 고급'의 경우 추가 처리
        if (['차세대통신 중급', '차세대통신 고급'].includes(selectedOption.value['dgrNm'])) {
          if (
            (selectedOption.value['dgrNm'] === '차세대통신 고급' && dgrNm === '추가사용(차세대통신 고급)') ||
            (selectedOption.value['dgrNm'] === '차세대통신 중급' && dgrNm === '추가사용(차세대통신 중급)')
          ) {
            if (additionalSeqc==0){
              additionalSeqc = options.value.find(opt => opt.dgrNm === dgrNm)?.seqc || 0;
            }
            additionalList.push(item.subjtCd);
          }
        }
      });
    });

    if (additionalList.length > 0) {
      requestData.push({
        subjtCds: additionalList,
        mdIssueDgrSeqc: additionalSeqc,
        userSeqc: userSeqc.value
      });
    }
  updateData(requestData);
  } else {
    // 초기값 없애고, 미선택시 신청 안되게 처리함 (241119)
    alert('신청할 마이크로디그리를 선택하세요')
  }
}

const updateData = async (requestData) => {
  const result = await updateDigitalBadgeApplyAPI(requestData)
  if (result.status === 200) {
    alert('신청이 완료되었습니다')
    closePopup();
    store.commit('setIsUpdated', true)
  }
}

function closePopup() {
  isTransitioning.value = true;
  setTimeout(() => {
    initializeData();
    isTransitioning.value = false;
    store.dispatch('closePopup', props.popupId);
  }, 200);
}

onUnmounted(() => {
  if (isPopupOpen.value) {
    closePopup();
  }
});

</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:70rem !important;
      .t2 {
        font-size: 1.8rem;
        font-weight: 500;
        color: #111;
      }
      table {
        th, td {
          padding: .7rem .2rem;
          line-height: 1.4;
        }
      }
      .scroll-box {        
        max-height:50rem !important;
        margin-top: 0 !important;
        .ta {        
          p {
            font-size: 1.4rem;
            color: #b1abab;
            strong {
              font-size: 1.5rem;
              font-weight: 500;
              color: #525252;
            }
          }
        }
        .ea {
          .box {
            padding:3rem 2rem;
            color: $wh;
            background:#5d9aff;
            border-radius: .5rem;
            .t3 {
              font-size: 1.6rem;
              letter-spacing: -.02rem;
            }
            .rad {
              $cw : 1.6rem;
              display: block;
              position: relative;
              margin-top:.5rem;
              font-size:1.7rem;      
              line-height: calc($cw + 1rem);
              cursor:pointer;
              i {
                display: block;
                position: absolute;
                width: $cw;
                height: $cw;
                top:1.5rem;
                left:1.5rem;
                border: .1rem solid $wh;
                border-radius: 50%;
                z-index: 2;
              }
              input {
                display: block;
                width: 100%;
                height: 0;            
              }
              span {
                display: block;
                padding:1rem 1rem 1rem 4rem;
                border-radius: 1rem;
                @include transition();
              }
              input:checked + i, &:hover input + i {
                background: $wh;
              }   
              input:checked + i::after, &:hover input + i::after{
                $pos : calc(50% - .5rem);
                content: "";
                display: block;
                position: absolute;
                width: 1rem;
                height: 1rem;
                top:$pos;
                left:$pos;
                background: $tm4;
                border-radius:50%;
              }
              input:checked + i + span, &:hover input + i + span {
                background: #4483ed;
              }
            } 
          }  
        } 
      }
      .pop-btn button {
        width: 12rem;
      }
    }        
  }
}
</style>