<template>
  <div class="viewMain" :class="{ scrolled: scrollPosition > 0 }">
    <router-view></router-view>
  </div>
</template>

<script>
import '@/assets/user/style/main.css'

export default {
  name: 'viewMain',
  props: {},
  data(){
    return {
      scrollPosition: 0,
    };
  },
  components : {
    // TopBar, mainNav, mainFooter,
  },
  mounted() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const header = document.querySelector('header');
      if (header) {
        header.classList.toggle('scrolled', window.scrollY > 0);
      }
      this.scrollPosition = window.scrollY;
    },

    scrollToTop() {
      // 페이지 상단으로 부드럽게 스크롤
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
}
</script>

<style scoped>


</style>


