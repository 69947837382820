<template>
  <header class="abs" :class="{ fix: isHeaderFixed }">
    <div class="top rel">
      <div class="inner flex__d__m">
        <router-link to="/lms/" replace class="logo block t-t-m-show"><img src="@/assets/lms/img/logo_wh.png" alt="NCCOSS 첨단분야 혁신 융합대학 LMS"></router-link>
        <top-menu></top-menu>
        <p class="ham-btm">
          <button type="button" @click="showAside">
            <span class="material-symbols-outlined">menu</span>
          </button>
        </p>
      </div>
    </div>
  </header>
</template>

<script>
import TopMenu from '@/components/lms/common/TopMenu.vue';

export default {
  components: { TopMenu },
  data() {
    return {
      isHeaderFixed: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);    
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 40) {
        this.isHeaderFixed = true;
      } else {
        this.isHeaderFixed = false;
      }
    },
    showAside() {
      const aside = document.querySelector('aside');
      document.documentElement.classList.add('disable');
      aside.classList.add('show');
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>