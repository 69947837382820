<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">담당자 등록</p>
        <div class="tr"><button type="button" class="add-btn">+ 추가</button></div>
        <div class="scroll-box">          
          <table class="tc w100">
            <colgroup>
              <col>
              <col>
              <col>
              <col>
              <col>
              <col style="width:10%">              
            </colgroup>
            <tr>
              <th>성명</th>
              <th>기관명</th>
              <th>부서명</th>
              <th>휴대폰번호</th>
              <th>이메일</th>
              <th>등록</th>
            </tr>
            <tr>
              <td>김지연</td>
              <td>(주)미디어그룹사람과숲</td>
              <td>미래기술사업부</td>
              <td>010-5564-7549</td>
              <td>usiwl@humanf.co.kr</td>
              <td><button type="button" class="ty2">삭제</button></td>
            </tr>
            <tr>
              <td>김지연</td>
              <td>(주)미디어그룹사람과숲</td>
              <td>미래기술사업부</td>
              <td>010-5564-7549</td>
              <td>usiwl@humanf.co.kr</td>
              <td><button type="button" class="ty2">삭제</button></td>
            </tr>
            <tr>
              <td>김지연</td>
              <td>(주)미디어그룹사람과숲</td>
              <td>미래기술사업부</td>
              <td>010-5564-7549</td>
              <td>usiwl@humanf.co.kr</td>
              <td><button type="button" class="ty2">삭제</button></td>
            </tr>
            <tr>
              <td>김지연</td>
              <td>(주)미디어그룹사람과숲</td>
              <td>미래기술사업부</td>
              <td>010-5564-7549</td>
              <td>usiwl@humanf.co.kr</td>
              <td><button type="button" class="ty2">삭제</button></td>
            </tr>
            <tr>
              <td><input type="text" class="w100"></td>
              <td><input type="text" class="w100"></td>
              <td><input type="text" class="w100"></td>
              <td><input type="number" class="w100"></td>
              <td><input type="email" class="w100"></td>
              <td><button type="button">등록</button></td>
            </tr>
          </table>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button">취소</button>
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:90rem;
      .add-btn {
        height: 3rem;
        margin-right:.8rem;
        padding:0 1.6rem;
        color:$wh;
        font-size: 1.4rem;
        line-height: 3rem;
        background: $tm8;
        border-radius: .5rem;
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }
  @media (max-width: #{$tablet - 1px}) {
    .popup.popup-page .popup-content {
      table button {
        width: 3rem;
        padding:0;
      }
      .pop-btn button {
        width: 8rem;
      }
    }
  }
}
</style>