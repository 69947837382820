<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">최종반납내역 확인서</p>
        <div class="scroll-box">          
          <table class="w100">
            <caption class="p-main-tit tl">사업현황</caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tr>
              <th>사업명</th>
              <td colspan="3">일상생활 작업 및 명령 수행 데이터(미디어그룹사람과숲)</td>
            </tr>
            <tr>
              <th>협약기관</th>
              <td>(주)미디어그룹사람과숲</td>
              <th>총괄책임자</th>
              <td>최현우</td>
            </tr>
            <tr>
              <th>사업기간</th>
              <td>2023-06-01 ~ 2023-11-30</td>
              <th>협약기간</th>
              <td>2023-06-01 ~ 2023-11-30</td>
            </tr>
            <tr>
              <th>총사업비</th>
              <td colspan="3" class="tr"><strong>2,250,000,000원</strong></td>
            </tr>
          </table>

          <table class="w100">
            <caption class="p-main-tit tl">정산금 반납내역</caption>
            <tr>
              <th>예산</th>
              <th rowspan="2">집행액</th>
              <th colspan="5">반납금액</th>
              <th rowspan="2">정산금<br>반납금액<br>(B)</th>
            </tr>
            <tr>
              <th>보조금/민간위탁<br>/출연금(A)</th>
              <th>사업비집행잔액(a)</th>
              <th>이자(b)</th>
              <th>수입금(c)</th>
              <th>불인정금액(d)</th>
              <th>계(a+b+c+d)</th>
            </tr>
            <tr>
              <td>2,250,000,000</td>
              <td>2,227,188,636</td>
              <td>22,811,364</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>22,811,364</td>
              <td>0</td>
            </tr>
          </table>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:90rem;
      .scroll-box {
        max-height:56rem;
        table:not(:first-child) {margin-top:2rem;}
        table {
          td {
            padding-left:1.5rem;
            padding-right:1.5rem;
          }
        }
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>