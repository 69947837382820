<template>
  <div :class="{ 'pop-wrap': true, 'active': isAnyPopupOpen }">
    <lms-pop-default :popupId="'popupDel-1'" :popupData="popupDel1" :popupLink="getPopups"/>
    <lms-pop-default :popupId="'popupDel-2'" :popupData="popupDel2" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDel-3'" :popupData="popupDel3" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDel-4'" :popupData="popupDel4" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDel-5'" :popupData="popupDel5" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupReg'" :popupData="popupReg" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupEdit'" :popupData="popupEdit" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupCancel'" :popupData="popupCancel" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDone'" :popupData="popupDone" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDoneYes'" :popupData="popupDoneYes" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDelCancel'" :popupData="popupDelCancel" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupDelCancelYes'" :popupData="popupDelCancelYes" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupAbnormalAccessPath'" :popupData="popupAbnormalAccessPath" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupPlayer'" :popupData="popupPlayer" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupNoData'" :popupData="popupNoData" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupUpdateRole'" :popupData="popupUpdateRole" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupUpdateRoleResult'" :popupData="popupUpdateRoleResult" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupUseTitle'" :popupData="getPopups" />
    <!-- 다운 추가 테스트 -->
    <lms-pop-default :popupId="'poopupUseTitlePerf'" :popupData="poopupUseTitlePerf" :popupLink="getPopups" />
    <lms-pop-default :popupId="'poopupUseTitlePerfDelete'" :popupData="poopupUseTitlePerfDelete" :popupLink="getPopups" />

    <lms-pop-default :popupId="'popupThisOneFiles'" :popupData="popupThisOneFiles" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupLogout'" :popupData="popupLogout" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupNoLogin'" :popupData="popupNoLogin" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupMyclassBack'" :popupData="popupMyclassBack" :popupLink="getPopups" />
    <lms-pop-default :popupId="'popupExam1'" :popupData="popupExam1" />
    <lms-pop-default :popupId="'popupExam2'" :popupData="popupExam2" />
    <lms-pop-default :popupId="'popupGuide1'" :popupData="popupGuide1" />
    <lms-pop-pms-basic :popupId="'popupPmsBasic'" />
    <lms-pop-pms-add :popupId="'popupPmsAdd'" />
    <lms-pop-pms1 :popupId="'popupPms1'" />
    <lms-pop-pms2 :popupId="'popupPms2'" />
    <lms-pop-pms3 :popupId="'popupPms3'" />
    <lms-pop-pms4 :popupId="'popupPms4'" />
    <lms-pop-pms5 :popupId="'popupPms5'" />
    <lms-pop-pms6 :popupId="'popupPms6'" />
    <lms-pop-my-class1 :popupId="'popupMyclass1'" :popupData="getPopups" />
    <lms-pop-my-class2 :popupId="'popupMyClass2'" :popupData="getPopups" />
    <lms-pop-msg-write :popupId="'popupMsgWrite'" />
    <lms-pop-msg-view :popupId="'popupMsgView'" />
    <lms-pop-badge-apply :popupId="'popupBadgeApply'" />
    <lms-pop-perf :popupId="'popupPerf'" />
    <lms-pop-perf-choice :popupId="'popupPerfChoice'" />
  </div>
</template>

<script>
import LmsPopDefault from '@/components/lms/popup/LmsPopDefault.vue';
import LmsPopPmsBasic from '@/components/lms/popup/LmsPopPmsBasic.vue';
import LmsPopPmsAdd from '@/components/lms/popup/LmsPopPmsAdd.vue';
import LmsPopPms1 from '@/components/lms/popup/LmsPopPms1.vue';
import LmsPopPms2 from '@/components/lms/popup/LmsPopPms2.vue';
import LmsPopPms3 from '@/components/lms/popup/LmsPopPms3.vue';
import LmsPopPms4 from '@/components/lms/popup/LmsPopPms4.vue';
import LmsPopPms5 from '@/components/lms/popup/LmsPopPms5.vue';
import LmsPopPms6 from '@/components/lms/popup/LmsPopPms6.vue';
import LmsPopMyClass1 from '@/components/lms/popup/LmsPopMyClass1.vue';
import LmsPopMyClass2 from '@/components/lms/popup/LmsPopMyClass2.vue';
import LmsPopMsgWrite from '@/components/lms/popup/LmsPopMsgWrite.vue';
import LmsPopMsgView from '@/components/lms/popup/LmsPopMsgView.vue';
import LmsPopBadgeApply from '@/components/lms/popup/LmsPopBadgeApply.vue';
import LmsPopPerf from '@/components/lms/popup/LmsPopPerf.vue';
import LmsPopPerfChoice from '@/components/lms/popup/LmsPopPerfChoice.vue';
import { mapGetters } from 'vuex';

export default {
  components: { 
    LmsPopDefault, 
    LmsPopPmsBasic, LmsPopPmsAdd, LmsPopPms1, LmsPopPms2, LmsPopPms3, LmsPopPms4, LmsPopPms5, LmsPopPms6, 
    LmsPopMyClass1, LmsPopMyClass2,
    LmsPopMsgWrite, LmsPopMsgView,
    LmsPopBadgeApply,
    LmsPopPerf, LmsPopPerfChoice,
  },
  computed: {
    ...mapGetters(['isAnyPopupOpen', 'getPopups']),
  },
  data() {
    return {
      popupDel1: { txt1: "삭제할 목록을 선택해주세요." },
      popupDel2: { txt1: "선택한 목록을 삭제하시겠습니까?", addBtn: true, nextDelOpen: true },
      popupDel3: { txt1: "삭제되었습니다." },
      popupDel4: { txt1: "선택한 목록을 수정하시겠습니까?", addBtn: true, nextDelOpen: true },
      popupDel5: { txt1: "삭제하시겠습니까?", addBtn: true, },
      popupReg: { txt1: "등록되었습니다." },
      popupEdit: { txt1: "수정되었습니다." },
      popupCancel: { txt1: "취소하시겠습니까?" },
      popupDone: { txt1: "수강 신청을 하시겠습니까?", addBtn: true, nextDelOpen: true },
      popupDoneYes: { txt1: "수강 신청이 완료되었습니다." },      
      popupDelCancel: { txt1: "취소하시겠습니까?", addBtn: true, nextDelOpen: true },
      popupDelCancelYes: { txt1: "취소되었습니다." },
      popupAbnormalAccessPath: { txt1: "비정상적인 접근 경로 입니다." },
      popupNoData: { txt1: "데이터가 없습니다.", txt2: "목록 페이지로 이동합니다." },
      popupUseTitle: { txt1: "" },
      // 다운 추가 (테스트)
      poopupUseTitlePerf: { txt1: "저장되었습니다."},
      poopupUseTitlePerfDelete: { txt1: "삭제되었습니다."},
      
      popupThisOneFiles: { txt1: "파일을 2개 이상 올릴수 없습니다." },
      popupPlayer: { txt1: "이어서 보시겠습니까?", addBtn: true, nextDelOpen: true },
      popupMyclassBack: { txt1: "강의를 수강중입니다.", txt2:"페이지를 이동하시겠습니까?", addBtn: true, nextDelOpen: true},
      popupUpdateRole: { txt1: "권한변경 하시겠습니까?", addBtn: true, nextDelOpen: true },
      popupUpdateRoleResult: { txt1: "권한 변경되었습니다." },
      popupLogout: { txt1: "로그아웃 되었습니다." },
      popupNoLogin: { txt1: "로그인 정보가 없습니다.", txt2: "다시 로그인 해주세요."},
      popupExam1: { txt1: "시험에 응시하지 않았습니다."},
      popupExam2: { txt1:"채점중입니다."},
      popupGuide1 : { txt1: "목록을 선택해주세요."},
    };
  }
}
</script>