<template>
  <article>
    <form action="">
      <h1 class="tit">성과 등록</h1>
      <DataForm ref="formData" @sendFormData="handleFormData" :compType="'add'"/>
      <div class="pop-btn flex__c__m mgt20">
        <button @click="props.closePopup(props.popup.id)" type="button" class="btn__rev">취소</button>
        <button @click="handleAdd(); openPopup('AddDone')" type="button" class="btn__base"> 등록</button>
        <!-- <button @click="handleAdd(); openPopup('AddDone')" type="button" class="btn__base">등록</button> -->
        <!-- <button @click="props.closePopup(props.popup.id); openPopup('AddDone')" type="button" class="btn__base">등록</button> -->
      </div>
    </form>
  </article>
</template>

<script setup>
import { defineProps, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import DataForm from '@/screens/cms/performance/popup/DataForm.vue';
import { uploadFileAPI, getCountOfAleadyAddedAPI } from '@/api'

const store = useStore();
const formData = ref(null)
const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
});

const handleAdd = () => {
  formData.value.sendFormData()
}

async function handleFormData(formData) {
  // 등록 로직
  try {
    await nextTick();
    // perfId가 2, 4인 것은 연도와 교과목명이 동일하면 등록못하게 해야함
    if (['2', '4'].includes(formData['perfId'])){
      const result = await getCountOfAleadyAddedAPI(store.getters.getVariables['uriPath'], formData)
      if (result.data > 0){
        alert('이미 등록된 과목입니다')
      } else {
        savePerfData(formData)
      }
    } else {
      savePerfData(formData)
    }
  } catch (error) {
    console.error(error)
  }
}

async function savePerfData (formData) {
  try {
    const response = await store.dispatch('savePopupData', {
     uriPath: store.getters.getVariables['uriPath'],
     data: formData
    })
    if(response.status === 200){
      // 파일처리
      if (formData['newFiles'] !== null && formData['newFiles'].length > 0) {
        manageFile(formData, response.data);
      }

      props.closePopup(props.popup.id)
      store.commit('setIsUpdated', true)
    }
  } catch (error) {
    console.error(error)
  }
}

async function manageFile(formData, seqc){
  const fileData = new FormData();
  formData['newFiles'].forEach(fileWrapper => {
    fileData.append('files', fileWrapper.file)
  })
  fileData.append('seqc', seqc)
  fileData.append('referenceTable', store.getters.getVariables['uriPath'])
  const fileResponse = await uploadFileAPI(fileData)
  if(fileResponse.status === 200){
    // props.closePopup(props.popup.id)
    // store.commit('setIsUpdated', true)
    console.log('file upload complete')
  }
}

</script>