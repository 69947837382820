<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">인건비(30% 이하)</p>        
        <div class="scroll-box">          
          <table class="tc w100">
            <colgroup>
              <col style="width:10%">
              <col style="width:20%">
              <col style="width:45%">
              <col style="width:25%">
            </colgroup>
            <tr>
              <th>선택</th>
              <th>날짜</th>
              <th>내용</th>
              <th>사용금액</th>
            </tr>
            <tr> <!-- 1페이지에 8개씩 셋팅 -->
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-01</td>
              <td>사업비 지출 내용</td>
              <td>￦530,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-05</td>
              <td>-</td>
              <td>￦310,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-10</td>
              <td>-</td>
              <td>￦100,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-13</td>
              <td>-</td>
              <td>￦7,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-01</td>
              <td>-</td>
              <td>￦530,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-05</td>
              <td>-</td>
              <td>￦310,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-10</td>
              <td>-</td>
              <td>￦100,000</td>
            </tr>
            <tr>
              <td>
                <label class="chk"><input type="checkbox"><i></i></label>
              </td>
              <td>2024-05-13</td>
              <td>-</td>
              <td>￦7,000</td>
            </tr>
          </table>
          <div class="flex__d__m list-btn mgt30">
            <button type="button" class="del-btn">삭제</button>
            <ul class="pop-page flex__d__m">
              <li><a href="">←</a></li>
              <li><a class="on" href="">1</a></li>
              <li><a href="">2</a></li>
              <li><a href="">3</a></li>
              <li><a href="">4</a></li>
              <li><a href="">5</a></li>
              <li><a href="">→</a></li>
            </ul>
            <button @click="openPopup('popupPmsAdd')" type="button" class="add-btn">추가</button>
          </div>
        </div>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:90rem;
      padding-bottom:4rem;
      .scroll-box {
        min-height: 45rem;
      }
      .chk {
        $cw : 2rem;
        display: inline-block;
        position: relative;
        vertical-align: middle;      
        cursor: pointer;
        input {
          width: $cw;
          height: $cw;
          vertical-align: sub;
          border: 1px solid $cl2;
        }
        i {      
          display: block;
          position: absolute;
          width: $cw;
          height: $cw;
          top:calc(50% - 1rem);
          left:0;
        }   
        input:checked + i{
          background:url($url+'check.png') no-repeat center;
        }
      }
      .list-btn {
        button {
          height: 3.5rem;
          padding:0 2.6rem;
          font-size: 1.6rem;
          line-height: 3.5rem;          
          border-radius: .5rem;
          border:.1rem solid $tm8;
        }
      }
      .del-btn {
        color:$tm8;
        background: $wh;
      }
      .add-btn {
        color:$wh;
        background: $tm8;
      }
      .pop-page {
        color:#999;
        font-weight:300;
        font-size: 1.5rem;
        gap:2rem;
        .on, a:hover {
          color:$tm1;
        }
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }
  @media (max-width: #{$tablet - 1px}) {
    .popup.popup-page .popup-content {
      .list-btn {
        button {
          padding:0 1rem;
        }
      }
      .pop-page {
        gap:1rem;
      }
      .pop-btn button {
        width: 8rem;
      }
    }
  }
}
</style>