
<template>
  <LmsIndex />
</template>


<script setup>
import LmsIndex from '@/components/lms/lmsIndex.vue';

import { ref, onUpdated } from 'vue';
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

const { cookies } = useCookies();
const userInfo = ref('');
const store = useStore();

onUpdated(() => {
  userInfo.value = cookies.get("userInfo");

  if (userInfo.value == null || userInfo.value == undefined || userInfo.value == '' || userInfo.value.length <= 0) {
    cookies.remove("userInfo")
    store.dispatch('openPopup', {id: 'popupNoLogin', data: '/'})
  }
});
</script>

