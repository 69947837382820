<template>
  <article>
    <form action="" @submit.prevent>
      <h1 class="tit">강좌 선택</h1>
      
      <div class="form-box tl mgt20">
        <div class="form-group search-box">
          <label for="" class="hidden">검색하기</label>
          <div class="box flex__d__t">
            <div class="la">              
              <select id="" v-moedel="selectedValue" class="w100">
                <option value="전체">전체</option>
                <!-- <option value="대학명">대학명</option> -->
                <option value="강좌명">강좌명</option>
                <option value="강좌번호">강좌번호</option>
              </select>              
            </div>
            <div class="ra flex__d">
              <input type="text" v-model="text" id="" @keyup.enter="search()" />
              <button type="button" @click.prevent="search()" class="btn__base">
                <span class="material-symbols-outlined">search</span>
              </button>
            </div>
          </div>
        </div>

        <div class="grid-table mgt10">
          <div class="grid-header item-box">
            <div class="item">선택</div>
            <div class="item">대학명</div>
            <div class="item">강좌명</div>
            <div class="item">강좌번호</div>
          </div>
          <div 
            v-for="course in courses" 
            :key="course.id" 
            class="grid-body item-box"
            :class="{ selected: selectedCourseId === course.kmoocSubId }"
            @click="toggleSelection(course)"
            >
            <!-- @click="toggleSelection(course.kmoocSubId)" -->

            <div class="item item-header">
              <label class="rad">
                <input 
                  type="radio" 
                  name="choice" 
                  :checked="selectedCourseId === course.kmoocSubId"
                  @click="toggleSelection(course)" 
                >
                <i></i>
              </label>
            </div>
            <div class="item">{{ course.univ }}</div>
            <div class="item">{{ course.subNm }}</div>
            <div class="item">{{ course.subNmbr }}</div>
          </div>
        </div>
      </div>

      <div class="pop-btn flex__c__m mgt30">
        <button @click="props.closePopup(props.popup.id)" type="button" class="btn__rev">취소</button>
        <button @click="handleBtnClick(); openPopup('PerfDataAdd')" type="button" class="btn__base">확인</button>
      </div>
    </form>
  </article>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';

const store = useStore();
const { cookies } = useCookies();
const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
});

const userUniv = ref('')
userUniv.value = cookies.get('userInfo').university
const courses = computed(() => store.getters.getSubjectList || {})

// 검색용
const selectedValue = ref('전체')
const text = ref('')

// select
const selectedCourseId = ref(null);
const selectedCoures = ref({})

function toggleSelection(course) { // kmoocSubId
  selectedCourseId.value = selectedCourseId.value === course.kmoocSubId ? null : course.kmoocSubId;
  selectedCoures.value = course;
}

// 검색
async function search() {
  if (selectedValue.value != '전체' && (text.value == '' || text.value == undefined || text.value == null)) {
    alert('검색어를 입력하세요')
  } else {
    await store.dispatch('fetchSubjectList', {selectedValue: selectedValue.value, text: text.value, univ: userUniv.value})
  }
}

async function handleBtnClick(){
  // course/popId
  store.commit('setSubTitle', {
    subTitle:   selectedCoures.value.subNm,
    subUniv: selectedCoures.value.univ
  });

  props.closePopup(props.popup.id);
}

</script>

<style lang="scss" scoped>
.cms {  
  .form-box {
    width: 50rem;
  }
  .grid-table {
    .item-box {
      display: grid;
      grid-template-columns: 5rem repeat(2, 1fr) 10rem;
      .rad {
        $size: 1.6rem;
        width: $size;
        height: $size;
        border: .1rem solid #ddd;
        border-radius: 50%;
        input {
          border: none;
          &:checked + i {
            $inSize: $size / 2;
            width: $inSize;
            height: $inSize;
            top: calc(50% - $inSize / 2);
            left: calc(50% - $inSize / 2);
            border-radius: 50%;
            background: $wh;            
          }
        }        
      }
    }
    .grid-body {
      cursor: pointer;
      &:hover, &.selected {
        color: $wh;
        background-color: #a9c4fd;
      }
    }
  }
}
</style>