<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">정산검토보고서</p>
        <div class="scroll-box">          
          <table class="w100">
            <caption class="p-main-tit tl">사업출연금</caption>
            <tr>
              <th>사업명</th>
              <td>일상생활 작업 및 명령 수행 데이터(미디어그룹사람과숲)</td>
            </tr>
            <tr>
              <th>사업자</th>
              <td>(주)미디어그룹사람과숲</td>
            </tr>
            <tr>
              <th>총괄책임자</th>
              <td>김지원</td>
            </tr>
            <tr>
              <th>사업기간</th>
              <td>2023년 11월 01일 ~ 2024-05-05</td>
            </tr>
          </table>
          <p class="add-txt tr">삼일회계법인(PwC컨설팅)</p>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {      
      .scroll-box {
        max-height:56rem;
        table {
          th {font-size:1.6rem;}
          td {padding-left:1rem;}
        }
        .add-txt {
          padding-top:1rem;
          font-size:1.4rem;
          color: $tm2;
          font-weight: 500;
        }
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>