<template>
  <nav class="navigation">
    <ul class="gnb">
      <li 
        v-for="item in items" 
        :key="item.path" 
        class="gnb-item"         
        :class="{ 'active': activeMenu === item.name , 'on': isActive(item) }" 
        @mouseover="handleHover(item.name)" 
        @mouseleave="handleLeave"
      >
        <template v-if="item.children">
          <p class="top-level">
            <i><img :src="isActive(item) ? item.onIcon : item.icon" alt="" /></i>
            <span>{{ item.name }}</span>
          </p>
          <ul :style="{ height: activeMenu === item.name ? heights[item.name] + 'px' : '0' }">
            <li v-for="child in item.children" :key="child.path" :class="{ 'on': route.path === child.path }">
              <router-link :to="child.path" class="child block">{{ child.name }}</router-link>
            </li>
          </ul>
        </template>
        <template v-else>
          <router-link :to="item.path" class="top-level">
            <i><img :src="isActive(item) ? item.onIcon : item.icon" alt="" /></i>
            <span>{{ item.name }}</span>
          </router-link>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

// icons
import bookIcon from '@/assets/cms/img/icons/book-open-reader-solid.svg';
import awardIcon from '@/assets/cms/img/icons/award-solid.svg';
import addressIcon from '@/assets/cms/img/icons/address-book-solid.svg';
import commentIcon from '@/assets/cms/img/icons/comment-dots-solid.svg';
import sackIcon from '@/assets/cms/img/icons/sack-dollar-solid.svg';
import chartIcon from '@/assets/cms/img/icons/chart-simple-solid.svg';
import diagramIcon from '@/assets/cms/img/icons/diagram-project-solid.svg';

import bookIconOn from '@/assets/cms/img/icons/hover/book-open-reader-solid.svg';
import awardIconOn from '@/assets/cms/img/icons/hover/award-solid.svg';
import addressIconOn from '@/assets/cms/img/icons/hover/address-book-solid.svg';
import commentIconOn from '@/assets/cms/img/icons/hover/comment-dots-solid.svg';
import sackIconOn from '@/assets/cms/img/icons/hover/sack-dollar-solid.svg';
import chartIconOn from '@/assets/cms/img/icons/hover/chart-simple-solid.svg';
import diagramIconOn from '@/assets/cms/img/icons/hover/diagram-project-solid.svg';

const items = ref ([
  {
    name: '교육과정관리',
    icon: bookIcon,
    onIcon: bookIconOn,
    children: [
      { path: '/lms/course', name: '교과목 목록' },
      { path: '/lms/list', name: '수강 목록' }
    ]
  },
  {
    name: '마이크로디그리',
    icon: awardIcon,
    onIcon: awardIconOn,
    children: [
      { path: '/lms/badge', name: '이수이력' },
      { path: '/lms/sgra', name: '이수신청' }
      // { path: '/lms/badge', name: '발급 내역' },
      // { path: '/lms/b-apply', name: '발급 신청' }
      // { title: "이수이력", link: "/lms/badge" },
      // { title: "이수신청", link: "/lms/sgra" },
    ]
  },
  { 
    name: '회원 관리',
    path: '/lms/member',    
    icon: addressIcon,
    onIcon: addressIconOn,
  },
  {
    name: '커뮤니티 관리',
    icon: commentIcon,
    onIcon: commentIconOn,
    children: [
      { path: '/lms/notice', name: '공지사항' },
      { path: '/lms/qna', name: 'Q&A' },
      { path: '/lms/dataroom', name: '자료실' }
    ]
  },
  { 
    name: '사업비 관리',
    path: '/cms/pms',
    icon: sackIcon,
    onIcon: sackIconOn,
  },
  { 
    name: '성과 관리',
    path: '/cms/performance',    
    icon: chartIcon,
    onIcon: chartIconOn,
  },
  {
    name: '로그 관리',
    icon: diagramIcon,
    onIcon: diagramIconOn,
    children: [
      { path: '/lms/system', name: '접근 이력' },
      { path: '/lms/statistics', name: '로그인 통계' }
    ]
  },
]);

const route = useRoute();
const activeMenu = ref('');
const heights = ref({});

// const currentMenu = computed(() => {
//   return items.value.find(item => 
//     item.path === route.path || (item.children && item.children.some(child => child.path === route.path))
//   )?.name || '';
// });
//currentMenu === item.name

const isActive = (item) => {
  if (item.children) {
    return item.children.some(child => child.path === route.path);
  }
  return item.path === route.path;
};

const handleHover = (name) => {
  activeMenu.value = name;
  calculateHeights();
};

const handleLeave = () => {
  activeMenu.value = '';
};

const calculateHeights = () => {
  const gnbItems = document.querySelectorAll('.gnb-item');
  gnbItems.forEach(item => {
    const childList = item.querySelector('ul');
    if (childList) {
      const liElements = childList.querySelectorAll('li');
      let totalHeight = 0;
      liElements.forEach(li => {
        totalHeight += li.offsetHeight;
      });
      heights.value[item.querySelector('.top-level').textContent.trim()] = totalHeight - 1;
    }
  });
};

onMounted(() => {
  calculateHeights();
});
</script>

<style lang="scss" scoped>
.cms {
  .navigation {    
    .gnb {
      .gnb-item {        
        cursor: pointer;
        @include transition($time:.3s);
        .top-level {
          display: flex;
          align-items: flex-start;
          gap: 1.8rem;
          padding: 1.8rem 1.8rem 1.8rem 2.2rem;
          line-height: 2rem;
          letter-spacing: 0;          
          i {
            display: flex;
            flex: 0 0 1.5rem;
            height: 2rem;
            align-items: center;
            img {
              width: 100%;
            }
          }
          span {
            flex: 1; 
          }
        }
        ul {
          overflow: hidden;
          @include transition($time:.3s);
          li {
            .child {
              padding: 1.2rem 1.2rem 1.2rem 5.5rem;
              font-size: 1.4rem;
            }
          }          
        }
        &.active:not(.on) {
          background: rgba(0, 0, 0, .2);
          ul {
            li {
              .child {                
                &:hover {
                  background: rgba(0, 0, 0, .1);
                }
              }
            }          
          }          
        }
        &.on {
          color: $primary;
          font-weight: 500;
          background: $wh;
          ul {
            li {
              .child:hover, &.on .child {
                color: $primary;
                background: rgba($primary, .1);
              }
              &.on{
                .child {                
                  &::before {
                    content: '';
                    display: inline-block;
                    width: .3rem;
                    height: .3rem;
                    vertical-align: top;
                    margin: .7rem .5rem 0 0;
                    border-radius: 50%;
                    background: $primary;
                  }
                }
              }
            }          
          }
        }
              
      }    
    }
  }
}
</style>