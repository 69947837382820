<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">세부비목별 집행내역서</p>
        <div class="scroll-box">
          <table v-for="(table, index) in tables" :key="index" class="tc w100">
            <caption class="p-tit w100 tl clear">
              <span class="inline fl">{{ table.caption }}</span>
              <small class="inline fr mgt5">단위 : 원</small>
            </caption>
            <thead>
              <tr>
                <th>번호</th>
                <th>집행방법</th>
                <th>집행일</th>
                <th>성명(거래처)</th>
                <th>지출금액</th>
                <th>부가세</th>
                <th>집행금액</th>
                <th>집행내역</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, rowIndex) in table.data" :key="rowIndex">
                <td>{{ item.number }}</td>
                <td>{{ item.method }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.tax }}</td>
                <td>{{ item.total }}</td>
                <td>{{ item.detail }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="total">
                <td colspan="4">소계</td>
                <td>{{ table.totalAmount }}</td>
                <td>{{ table.totalTax }}</td>
                <td>{{ table.total }}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
      tables: [
        {
          caption: '[인건비 ＞ 보수]',
          data: [
            { number: 1, method: "계좌이체", date: "2023-12-11", name: "11월급여 현물 보수", amount: "65,600,990", tax: "0", total: "65,600,990", detail: "11월급여 현물 보수" },
            { number: 2, method: "계좌이체", date: "2023-12-11", name: "11월급여 지원금 보수", amount: "22,472,830", tax: "0", total: "22,472,830", detail: "11월급여 지원금 보수" }
          ],
          totalAmount: 678474820,
          totalTax: 0,
          total: 678474820
        },
        {
          caption: '[인건비 ＞ 상용임금]',
          data: [
            { number: 1, method: "계좌이체", date: "2023-12-11", name: "11월급여 현물 보수", amount: "65,600,990", tax: "0", total: "65,600,990", detail: "11월급여 현물 보수" },
            { number: 2, method: "계좌이체", date: "2023-12-11", name: "11월급여 지원금 보수", amount: "22,472,830", tax: "0", total: "22,472,830", detail: "11월급여 지원금 보수" }
          ],
          totalAmount: 678474820,
          totalTax: 0,
          total: 678474820
        },
      ]
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:90rem;
      .scroll-box {
        max-height:56rem;
        table:not(:first-child) {margin-top:2rem;}
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>