import ViewCms from '@/view/cms/CmsApp.vue';

export default {
  path: '/cms',
  name: 'CMS',
  component: ViewCms,
  meta: {
    roles: [
      "ROLE_ADMIN",
      "ROLE_PROFESSOR",
      // "ROLE_STUDENT",
      "ROLE_SUPER_ADMIN",
      "ROLE_SUPER",
    ],
  },
  children: [
    {
      path: '',
      name: 'CmsMain',
      component: () => import('@/screens/cms/main/CmsMain.vue'),
    },
    {
      path: 'pms',
      name: 'PmsList',      
      component: () => import('@/screens/cms/pms/PmsList.vue'),
      meta: { title: '사업비 관리' },
    },
    {
      path: 'performance',
      name: 'PerfList',      
      component: () => import('@/screens/cms/performance/PerfList.vue'),
      meta: { title: '성과 관리' },
    },
  ],
};