import { createApp } from 'vue'
import App from './App.vue'
import { router } from '@/router/index.js'

// Kpanda froala ADD ##############################################################################
import vueFroala from 'vue-froala-wysiwyg'

const app = createApp(App)
// Kpanda froala ADD ##############################################################################
app.use(vueFroala)
app.use(router)

// -------- LMS ----------
import store from '@/store'
import lmsCommon from '@/api/lmsCommon.js';
app.use(store).use(lmsCommon)

// popup
app.mixin({
  methods: {
    openPopup(id, data, gubun) {
      this.$store.dispatch('openPopup', { id, data, gubun });
    },
  },
});
// -------- LMS ----------
app.mount('#app')