<template>
  <div class="lms">
    <div class="wrap">
      <div>
        <header-comp />
        <router-view :key="$route.fullPath" @LoadingPopupOpen="LoadingPopupOpen" />
        <footer-comp />
        <lms-popup />
        <loading-comp :LoadingValue="this.isLoading" @closeLoading="closeLoading"/>
      </div>    
    </div> <!--// wrap end -->
  </div>  
</template>

<script>
import '@/assets/lms/style/css/reset.min.css'
import '@/assets/lms/style/css/common.css'
import '@/assets/lms/style/css/style.css'

import HeaderComp from '@/components/lms/common/HeaderComp.vue'
import FooterComp from '@/components/lms/common/FooterComp.vue'
import LmsPopup from '@/components/lms/popup/LmsModal.vue'
import LoadingComp from '@/components/lms/common/LoadingComp.vue'

export default {
  components: { HeaderComp, FooterComp, LmsPopup, LoadingComp },
  data() {
    return {
      isLoading : false
    };
  },
  methods : {
    LoadingPopupOpen(value) {
      this.isLoading = value
    },
    closeLoading(value) {
      this.isLoading = value
    }
  }
}
</script>