<script setup>
  import {onMounted} from 'vue'

  onMounted(() => {

  })
  function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
</script>
<template>
  <button class="top-bar" @click="scrollToTop" >
    <span class="material-symbols-outlined">arrow_upward</span>
  </button>
</template>

<style scoped lang="scss">
.top-bar {
  position: fixed;
  height: 40px;
  width: 40px;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  text-align: center;
  background-color: #000;
  box-shadow: 0px 0px 3px 1px #000;
  color: var(--mint);
}

span {
  color: #86f8ff;
}
</style>
