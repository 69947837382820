<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="tit"><span class="material-symbols-outlined">mail</span> 쪽지 보내기</p>
        <form class="msgForm">
          <ul>
            <li class="grid">
              <div><p>받는사람</p></div>
              <div class="rel">
                <input type="text" @focus="openListBox" @blur="closeListBox" class="w100">
                <div v-show="isListBoxOpen" class="list-box abs">
                  <ul>
                    <li v-for="(item, index) in items" :key="index">
                      <strong v-html="highlightText(item.name)" class="mgr10"></strong>
                      <span v-if="item.email" v-html="highlightText(item.email)"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="grid">
              <div><p>제목</p></div>
              <div><input type="text" class="w100"></div>
            </li>
            <li class="grid">
              <div><p>내용</p></div>
              <div><textarea type="text" class="w100"></textarea></div>
            </li>
          </ul>
          <div class="pop-btn tc">
            <button @click="closePopup" type="button" class="ty2">취소</button>
            <button @click="closePopup" class="ty3">보내기</button>
          </div>
        </form>
      </div>      
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
      isListBoxOpen: false,
      items: [
        { name: '김교수', email: 'humanf@humanf.co.kr' },
        { name: '이교수', email: 'humanf@humanf.co.kr' },
        { name: '최교수', email: 'humanf@humanf.co.kr' },
        { name: '성교수', email: 'humanf@humanf.co.kr' },
        { name: '@한교수', email: '' },
        { name: '@박교수', email: '' },
        { name: '@이지원', email: '' },   
        { name: '@한교수', email: '' },
        { name: '@김민지', email: '' },
        { name: '@이장원', email: '' },       
      ]
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    },
    highlightText(text) {
      if (text.includes('@')) {
        return text.replace('@', '<span style="color: #5691f3; font-weight:500">@</span>');
      }
      return text;
    },
    openListBox() {
      this.isListBoxOpen = true;
    },
    closeListBox() {
      this.isListBoxOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      width:56rem;
      .tit {
        .material-symbols-outlined {
          font-size:2.8rem;
          font-weight:normal;
        }
      }
      .msgForm {
        > ul {
          padding-top:1rem;
          > li {
            grid-template-columns: 7.6rem auto;
            grid-gap: 1rem;
            padding:1rem 0;
            > div {
              $txt-h:3.6rem;
              p {
                font-size: 1.8rem;                
                font-weight: 500;
                line-height: $txt-h;              
              } 
              input, textarea {
                border:.1rem solid #eee;
                border-radius:.5rem;
              }
              input {
                height: $txt-h;
                padding:0 1rem;
                color:#000;
              }
              textarea {
                height: 14rem;
                padding:1.4rem;
                line-height:2.2rem;
              }
              .list-box {
                top:calc(100% - .1rem);
                left:0;
                width: 100%;
                height: 19rem;
                background: $wh;
                border:.1rem solid #eee;
                border-radius:.5rem;
                box-shadow: .2rem .2rem 1rem .2rem rgba(0,0,0,.1);
                overflow: auto;
                z-index:2;
                li {
                  padding:.6rem 1rem;
                  color:#000;
                  cursor: pointer;
                  &:hover {
                    background:$cl3;
                  }
                }
              }              
            }
          }
        }
        .pop-btn button {
          width: 10rem;
        }
      }
      @media (max-width: #{$tablet - 1px}) {
        .msgForm > ul > li > div textarea {
          height: 18vh;
        }
      }    
    }        
  }  
}
</style>