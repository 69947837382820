<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="tit"><span class="material-symbols-outlined">mail</span> 쪽지 확인하기</p>
        <ul>
          <li class="grid">
            <div><p>보낸사람</p></div>
            <div>윤종현</div>
          </li>
          <li class="grid">
            <div><p>제목</p></div>
            <div>연구실 모임 일정을 공유합니다.</div>
          </li>
          <li class="grid">
            <div><p>내용</p></div>
            <div class="scroll-box">
              <pre>안녕하세요,

연구실 모임 일정을 공유드립니다. 아래는 모임 일정과 관련된 자세한 내용입니다.

- 일시: 2024년 4월 1일 (목요일) 오후 2시
- 장소: 연구실 회의실
- 안건: 최근 진행된 연구 프로젝트에 대한 토론 및 향후 계획 논의
- 참석자: 연구실 구성원 전체

참석이 어려우신 분은 미리 연락 주시기 바랍니다.

감사합니다.</pre>
            </div>
          </li>
        </ul>
        <div class="pop-btn tc">
          <button @click="closePopup" type="button" class="ty3">확인</button>
        </div>
      </div>      
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      width:56rem;
      .tit {
        .material-symbols-outlined {
          font-size:2.8rem;
          font-weight:normal;
        }
      }
      ul {
        padding-top:1rem;
        > li {
          grid-template-columns: 8rem auto;
          grid-gap: 1rem;
          padding:1rem 0;
          line-height:2.5rem;
          border-bottom:.1rem solid #eee;
          > div {
            $txt-h:3.6rem;
            p {
              font-size: 1.8rem;
              font-weight: 500;
              color: $bk;
              line-height: $txt-h;
            }
            &.scroll-box {
              max-height:18rem;
              margin-top:0;
            }
            &:first-child {
              border-right:.1rem solid #eee;
            }
            &:last-child {
              padding:.6rem 3rem 1rem 1rem;
            }
          }
        }
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>