import { ref } from 'vue';

export const getUniversityName = (universityCode) => {
  switch (universityCode) {
    case 'kmu': return '국민대학교';
    case 'uos': return '서울시립대학교';
    case 'uc': return '울산과학대학교';
    case 'cnu': return '전남대학교';
    case 'kau': return '한국항공대학교';
    case 'entire' : return '전체 참여대학';
    default: return '국민대학교';
  }
}

export const getUniversityCode = (universityName) => {
  switch (universityName) {
    case '국민대학교': return 'kmu';
    case '서울시립대학교': return 'uos';
    case '울산과학대학교': return 'uc';
    case '전남대학교': return 'cnu';
    case '한국항공대학교': return 'kau';
    case '전체참여대학' : return 'entire';
    default: return 'kmu';
  }
}

// 성과관리 PerfDetail, LmsPopPerf에서 쓸 js
export const isPopupOpend = ref(false)
// header에 들어갈 리스트 값들
// core
// 1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 15, 18, 22, 24, 25, 26 연도관련 컬럼명 학년도에서 연도로 통일하여 수정함
const header1 = ['컨소시엄명', '대학명', '개발연도', '구분', '개발주체', '교육과정명', '비고', '첨부파일'];
const header2 = ['컨소시엄명', '대학명', '개발연도', '학기', '구분', '개발주체', '교과목명', '비고', '첨부파일'];
const header3 = ['컨소시엄명', '대학명', '운영연도', '운영학기', '구분', '교육과정명', '운영건수', '운영교과목명', '비고', '첨부파일']; 
const header4 = ['컨소시엄명', '대학명', '운영연도', '운영학기', '구분', '운영교과목명', '운영건수', '비고', '첨부파일']; 
const header5 = ['컨소시엄명', '대학명', '운영연도', '운영학기', '구분', '교과목명', '이수자수(총합)' , '비고', '첨부파일'];  // 5
const header6 = ['컨소시엄명', '대학명', '운영연도', '운영학기', '구분', '교육과정명', '이수자수(총합)' , '비고', '첨부파일'] // 6
const subHeader4 = ['이공계', '비이공계', '합계'] // 5, 6
const header7 = ['컨소시엄명', '대학명', '운영연도', '운영학기', '구분', '실적(%, 수)', '비고', '첨부파일']; // 7, 8, 9
const header10 = ['컨소시엄명', '이수자수', '이수학생 소속대학', '운영연도', '교과목명', '교과목 운영 컨소시엄', '교과목 운영 대학', '비고', '첨부파일'];
const header11 = ['컨소시엄명', '대학명', '공동활용대학', '운영연도', '운영학기', '교과목명', '교과목 운영 대학', '운영건수', ' 비고', '첨부파일']; // 11, 12
const header12 = ['컨소시엄명', '대학명', '공동활용대학', '운영연도', '운영학기', '교과목명', '교과목 운영 대학', '이수자수', ' 비고', '첨부파일']; // 11, 12
const header13 = ['컨소시엄명', '대학명', '학년도', '전임/비전임', '임용일자', '교원정보', '비고', '첨부파일']; // 13, 14
const subHeader13 = ['성명', '전공(경력)', '역할', '채용형태'];
const header15 = ['컨소시엄명', '대학명', '운영연도', '설문참여대상자(명)', '설문참여자(명)', '만족도평균(점)', '비고', '첨부파일'];
const header16 = ['컨소시엄명', '대학명', '지역인프라', '활용연도', '활용실적(건)', '활용인원(명)', '활용내용', '활용형태', '비고', '첨부파일'];
const header17 = ['컨소시엄명', '대학명', '구분', '(기관)전문가명', '활용연도', '활용실적(건)', '활용내용', '비고', '첨부파일'];
// self
const header18 = ['대학명', '운영연도', '운영일자', '교육명', '이수자수', '대상자', '비고', '첨부파일']
// 강좌구성현황 추가(240905)
// const header19 = ['대학명', '강좌명', '강좌번호', '책임 교수명', '참여형태', '강좌주차수', '강좌수준', '강좌소개', '참여교원', '사업실무담당자','비고','첨부파일']
const header19 = ['대학명', '강좌명', '강좌번호', '책임 교수명', '참여형태', '강좌주차수', '강좌수준', '강좌소개', '참여교원', '비고','첨부파일']
const header20 = ['대학명', '강좌명', '개발여부', '개발연도', '개발시기', '자체평가여부', '자체평가 진행시기', '품질검수여부', '품질검수 진행시기', '비고', '첨부파일'] // 20
const header21 = ['대학명', '강좌명', '운영여부', '운영연도', '운영시기', '운영회차', '수강신청인원', '수료인원', '학점연계여부', '이수증발급여부', '비고', '첨부파일'] // 21
const header22 = ['대학명', '운영연도', '운영일자', '현장실습명', '학생 수', '비고', '첨부파일']
const header23 = ['대학명', '학년도', '참여교원(명)', '수혜교원(명)', '수혜율(%)', '비고', '첨부파일']
const header24 = ['대학명', '운영연도', '운영일자', '세부프로그램명', '학생 수', '세부내용', '비고', '첨부파일']
// 기타
const header25 = ['대학명', '운영연도', '일시', '경진대회명', '참가자 수', '경진대회 내용', '비고', '첨부파일']
const header26 = ['대학명', '운영연도', '일시', '위원회명', '위원회 역할', '성과내용(의결사항)', '비고', '첨부파일']

export function setTableHeaders(perfId) {
  switch (perfId) {
    case "1" : return header1;
    case "2" : return header2;
    case "3" : return header3;
    case "4" : return header4;
    case "5" : return header5;
    case "6" : return header6;
    case "7" : 
    case "8" : 
    case "9" : return header7;
    case "10" : return header10;
    case "11" : return header11;
    case "12" : return header12;
    case "13" : 
    case "14" : return header13;
    case "15" : return header15; 
    case "16" : return header16; 
    case "17" : return header17; 
    case "18" : return header18; 
    case "19" : return header19;
    case "20" : return header20;
    case "21" : return header21;
    case "22" : return header22;
    case "23" : return header23;
    case "24" : return header24;
    case "25" : return header25;
    case "26" : return header26;
    default: break;
  }
}

export function setSubHeader(perfId) {
    switch(perfId) {
        case "5":
        case "6": return subHeader4;
        case "13" :
        case "14": return subHeader13;
        default: break;
    }
}

const columnsArray = [
    { keys: ['대학명'], value: 'univ' },
    { keys: ['학기', '운영학기'], value: 'semstr' },
    { keys: ['구분'], value: 'sep' },
    { keys: ['학년도', '개발학년도', '운영학년도', '활용연도', '운영연도', '개발연도'], value: 'year' },
    { keys: ['개발주체'], value: 'leadDev' },
    { keys: ['교과목명', '운영교과목명', '강좌명'], value: 'subNm' },
    { keys: ['교육과정명'], value: 'dgrNm' },
    { keys: ['운영건수', '건수'], value: 'oprtCnt' },
    { keys: ['이수자수', '수료인원', '합계'], value: 'compCnt' },
    // { keys: ['이수자수', '이수자수(총합)', '수료인원', '합계'], value: 'compCnt' },
    { keys: ['운영일시', '일시', '운영일자'], value: 'oprtDate' },
    { keys: ['현장실습명', '세부프로그램명', '경진대회명', '교육명'], value: 'actNm' },
    { keys: ['경진대회 내용', '세부내용'], value: 'actDetail'},
    { keys: ['학생 수', '참여교원(명)', '참가자 수', '수강신청인원'], value: 'partCnt' },
    { keys: ['비고'], value: 'etc' },
    { keys: ['첨부파일'], value: 'files' },
    { keys: ['이공계'], value: 'sciCnt' },
    { keys: ['비이공계'], value: 'nonSciCnt' },
    { keys: ['실적', '실적(%, 수)'], value: 'arch' },
    { keys: ['이수학생 소속대학'], value: 'stdnUniv' },
    { keys: ['교과목 운영 대학'], value: 'subUniv' },
    { keys: ['교과목 운영 컨소시엄'], value: 'subCons' },
    { keys: ['공동활용대학'], value: 'utilUniv' },
    { keys: ['전임/비전임'], value: 'ftStatus' },
    { keys: ['임용일자'], value: 'apptDate' },
    { keys: ['소계'], value: 'subtotal' },
    { keys: ['성명', '책임 교수명'], value: 'prfsNm' },
    { keys: ['전공(경력)'], value: 'prfsExp' },
    { keys: ['역할'], value: 'prfsRole' },
    { keys: ['채용형태'], value: 'empType' },
    { keys: ['설문참여대상자(명)'], value: 'survTargetCnt' },
    { keys: ['설문참여자(명)'], value: 'survPartCnt' },
    { keys: ['만족도평균(점)'], value: 'statisAvg' },
    { keys: ['지역인프라'], value: 'infra' },
    { keys: ['활용실적(건)'], value: 'perfCnt' },
    { keys: ['활용실적(명)'], value: 'perfPers' },
    { keys: ['활용인원(명)'], value: 'persCnt' },
    { keys: ['활용형태'], value: 'utilType' },
    { keys: ['활용내용'], value: 'utilDetail' },
    { keys: ['(기관)전문가명'], value: 'instExpNm' },
    { keys: ['대상자'], value: 'target' },
    { keys: ['수혜교원(명)'], value: 'beneCnt' },
    { keys: ['수혜율(%)'], value: 'beneRate'},
    { keys: ['위원회명'], value: 'commNm'},
    { keys: ['위원회 역할'], value: 'commRole'},
    { keys: ['성과내용(의결사항)'], value:'archDetail'},
    // 여기부터 강좌구성현황 컬럼 추가
    { keys: ['강좌번호'], value: 'subNmbr'},  // 어떤 값들어가는지 모름, 숫자만 넣도록 처리하지않음
    { keys: ['참여형태'], value: 'partType'},
    { keys: ['강좌주차수'], value: 'subWeek'},
    { keys: ['강좌수준'], value: 'subLevel'},
    { keys: ['강좌소개'], value: 'subIntro'},
    { keys: ['참여교원'], value: 'partPrfs'},
    { keys: ['사업실무담당자'], value: 'oprMgr'},
    { keys: ['개발여부'], value: 'devStatus' },
    { keys: ['개발시기'], value: 'devPeriod'},
    { keys: ['자체평가여부'], value: 'selfRepStatus'},
    { keys: ['자체평가 진행시기'], value: 'selfRepPeriod'},
    { keys: ['품질검수여부'], value: 'qcStatus'},
    { keys: ['품질검수 진행시기'], value: 'qcPeriod'},
    { keys: ['운영여부'], value: 'oprStatus'},
    { keys: ['운영시기'], value: 'oprPeriod'},
    { keys: ['운영회차'], value: 'oprCycle'},
    { keys: ['학점연계여부'], value: 'dgrLinkStatus'},
    { keys: ['이수증발급여부'], value: 'certIssueStatus'}
  ];

const columns = columnsArray.reduce((acc, { keys, value }) => {
    keys.forEach(key => acc[key] = value);
    return acc;
}, {});

export function getColName(header) {
    const colName = columns[header];
    return colName;
}

export function getItemValue(item, header) {
    const columnKey = columns[header];
    
    // 컨소시엄 하드코딩 (나중에 수정해야되면 수정하기)
    if (header == '컨소시엄명') {
        return '차세대통신';
    }
    // 날짜 컬럼 출력형식 변경
    if (columnKey == 'oprtDate' || columnKey == 'apptDate') {
        const dateKey = formDate(item[columnKey])
        return dateKey;
    }
    // 학생수 합계 컬럼은 이공계 + 비이공계 이수자 합계 계산
    if (header == '합계') {
        const sumStdnCnt = item['sciCnt'] + item['nonSciCnt']
        return sumStdnCnt;
    }
    return item[columnKey];
}

export const formDate = (dateString) => {
	if (dateString)  {
    const match = dateString.match(/(\d{4})-\s*(\d{1,2})-\s*(\d{1,2})/);
    if (match) {
      const year = match[1];
      const month = match[2].padStart(2, '0');
      const day = match[3].padStart(2, '0');
      //const formattedDate = `${year}.${month}.${day}` // 2024.08.08
      const formattedDate = `${year}-${month}-${day}` // 2024-08-08
      return formattedDate;
    }
  }
}

export function setLocaleDateTime(dateString) {
  let formattedDate = ''
  let validDate = ''
  let month = ''
  
  if (dateString) {
    const match4 = dateString.match(/^\d{6}$/)
    const match3 = dateString.match(/^\d{8}$/)

    const match = dateString.match(/(\d{4})-\s*(\d{1,2})-\s*(\d{1,2})/)
    const match1 = dateString.match(/(\d{4})\.\s*(\d{1,2})\.\s*(\d{1,2})/)
    const match2 = dateString.match(/(\d{4})\/\s*(\d{1,2})\/\s*(\d{1,2})/)

    if (match) {
      month = setSecond(match[2])
      formattedDate = `${match[1]}-${month}-${match[3].padStart(2, '0')}`
      validDate = checkValidDate(formattedDate)
      return validDate;
    } else if (match1) {
      month = setSecond(match1[2])
      formattedDate = `${match1[1]}-${month}-${match1[3].padStart(2, '0')}`
      validDate = checkValidDate(formattedDate)
      return validDate;
    } else if (match2) {
      month = setSecond(match2[2])
      formattedDate = `${match2[1]}-${month}-${match2[3].padStart(2, '0')}`
      validDate = checkValidDate(formattedDate)
      return validDate;
    } else if (match3) {
      // 자기 멋대로 입력할 수 있어서 예외로 추가함
      // 예: 20240302
      formattedDate = `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`
      validDate = checkValidDate(formattedDate)
      return validDate;
    } else if (match4) {
      // 자기 멋대로 입력할 수 있어서 예외로 추가함
      // 240902
      formattedDate = `20${dateString.slice(0, 2)}-${dateString.slice(2, 4)}-${dateString.slice(4, 6)}`
      validDate = checkValidDate(formattedDate)
      return validDate;
    } else {
      return '지원하지 않는 날짜 형식입니다.';
    }
  }
}

// 유효하지 않은 날짜 검증
const checkValidDate = (formattedDate) => {
  let validString = '';
  if (formattedDate == null) return true; // 공백허용
  
  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  if (regex.test(formattedDate)) {
    const [year, month, day] = formattedDate.split("-").map(Number, 10);
    const newDate = new Date(year, month - 1, day);

    if (newDate.getFullYear() === year && newDate.getMonth() === month - 1 && newDate.getDate() === day) {
      validString = formattedDate;
      return validString;
    } else {
      validString = '유효하지 않은 날짜입니다.';
      return validString;
    }
  }
  
  return '유효하지 않은 날짜입니다.';
};


const setSecond = (date) => {
  const month = parseInt(date, 10); 
  if (month >= 1 && month <= 12) {
    return date.padStart(2, '0');
  } else {
    return null; 
  }
}

// 단위 설정하는 함수
// export function setUnitName(perfId) {
//   if (['5', '6', '10', '12', '17', '18', '22', '24', '25'].includes(perfId)) {
//     return '명';
//   } else if (['7', '8', '9', '23'].includes(perfId)) {
//     return '%';
//   } else if (perfId === '15') {
//     return '점';
//   } else {
//     return '건';
//   }
// }

// API 요청 URI 세팅
const advEduDevIds = ['1', '2'];
const advEduOprIds = ['3', '4', '5', '6'];
const careerAchIds = ['7', '8', '9']; // careerAch
const advEduShareIds = ['10', '11', '12'];
const advFacultyIds = ['13', '14'];
const advEduSatIds = ['15'];
const advLocResIds = ['16', '17'];
const empEduCompIds = ['18'];
const kmoocSubId = ['19'];
const kmoocOprIds = [ '20', '21'];
const advFldTrngIds = ['22'];
const facultyAchEvalIds = ['23'];
const noncurrPartIds = ['24'];
const compPartIds = ['25'];
const consCommOprIds = ['26'];

export function getUriValue(perfId) {
  if (advEduDevIds.includes(perfId)) { return 'advEduDev'
  } else if (advEduOprIds.includes(perfId)) { return 'advEduOpr'
  } else if (careerAchIds.includes(perfId)) { return 'careerAch'
  } else if (advEduShareIds.includes(perfId)) { return 'advEduShare'
  } else if (advFacultyIds.includes(perfId)) {  return 'advFaculty'
  } else if (advEduSatIds.includes(perfId)) { return 'advEduSat'
  } else if (advLocResIds.includes(perfId)) { return 'advLocRes'
  } else if (empEduCompIds.includes(perfId)) { return 'empEduComp'
  } else if (kmoocOprIds.includes(perfId)) { return 'kmoocOpr'
  } else if (advFldTrngIds.includes(perfId)) { return 'advFldTrng'
  } else if (facultyAchEvalIds.includes(perfId)) { return 'facultyAchEval'
  } else if (noncurrPartIds.includes(perfId)) { return 'noncurrPart'
  } else if (compPartIds.includes(perfId)) { return 'compPart'
  } else if (consCommOprIds.includes(perfId)) { return 'consCommOpr'
  } else if (kmoocSubId.includes(perfId)) { return 'kmoocSub'
  }

}

const integerHeader = ['year','oprtCnt', 'compCnt', 'partCnt', 'sciCnt', 'nonSciCnt', 'subtotal', 'oprCycle',
                        'survTargetCnt', 'survPartCnt', 'perfCnt', 'perfPers', 'persCnt', 'target', 'beneCnt'] // 정수
const decimalHeader = ['arch', 'statisAvg', 'beneRate'] 
const dateHeader = ['oprtDate', 'apptDate'];
const notNullHeader = ['year'];

// 입력 확인
function isNotEmpty(value) {
  if (value === null || value === undefined || value.toString().trim().length < 1) {
    return false;
  }
  return true;
}

// 정수만 입력 되었는지 확인
function isInteger(value) {    
  // console.log('isInteger? > ', typeof value)
  if( value === null  ) return true; // 공백일 때 허용
  const regex = /^-?\d+$/;
  return regex.test(value);
}

// 숫자(정수, 소수)
function isDecimal(value) {
  if( value === null  ) return true; // 공백일 때 허용
  const regex = /^-?\d+(\.\d+)?$/;
  return regex.test(value);
}

// 날짜 형식 확인
function isDate(data, key, value) {
  if( value === null  ) return true; // 공백일 때 허용  
  const dateString = setLocaleDateTime(value);
  if(dateString === "지원하지 않는 날짜 형식입니다." || dateString === "유효하지 않은 날짜입니다."){
    alert(dateString);
    return false;
  }
  
  data[key] = dateString;
  return true;
}

export function checkRegx(data) {
  for (const [key, value] of Object.entries(data)) {  
    // console.log(key)
    // 낫 널 확인
    let col = columns[key]
    if(notNullHeader.includes(col)){
      if(!isNotEmpty(value)) {
        alert(`${key}는 필수값 입니다.`);
        return false;
      } else {
        const valueString = typeof value === 'number' ? value.toString() : value;
        if (typeof value === 'string'){
          const matchYear = valueString.match(/^\d{4}$/)
          if(!matchYear) {
            alert('연도는 4자리로 입력하세요. (예. 2024)')
            return false;
          } 
        } 
      }
    }    

    // 정수
    if(integerHeader.includes(col)){   
      if(!isInteger(value)){
        alert(`${key}는 정수만 입력하세요`);
        return false;
      }
    }

    // 소수
    if(decimalHeader.includes(col)){
      if(!isDecimal(value)){
        alert(`${key}는 숫자만 입력하세요`);
        return false;
      }
    }

    // 날짜
    if(dateHeader.includes(col)){
      if(!isDate(data, key, value)){
        return false;
      }
    }
  }

  return true;

}