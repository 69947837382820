<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m">    
  <!-- <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">     -->
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <div class="flex__d__m">
          <p class="t1 tit" v-if="universityCode!=='entire'">성과지표 등록</p>  
          <p class="t1 tit" v-else>성과지표</p>  
          <!-- <div class="tr mgt30" v-if="universityCode !== 'entire'"> -->
            <button type="button" v-if="universityCode !== 'entire' && isMine" class="del-btn" @click.prevent="deletePerfDetail()">삭제</button>
          <!-- </div> -->
        </div>
        <div class="scroll-box">
          <div>
            <table class="w100">
              <colgroup>
                <col style="width:30%">
                <col>
              </colgroup>              
              <tr>
                <th colspan="2">세부지표명</th>
              </tr>
              
              <template v-for="header in tableHeaders" :key="header">
                <tr v-if="header !== '첨부파일' && header !== '교원정보' && header !== '이수자수(총합)' && header != '대학명' && header != '전임/비전임'
                 && header != '활용형태' && header!= '학기' && header!= '운영학기' && header != '구분' && header!= '세부내용' && header != '개발주체'
                 && header != '참여형태' && header != '강좌수준' && header != '강좌소개' && header != '개발시기' && header != '자체평가 진행시기' 
                 && header != '품질검수 진행시기' && header != '운영시기' && header !='자체평가여부' && header != '품질검수여부' 
                 && header != '개발여부' && header != '운영여부' && header != '학점연계여부' && header != '이수증발급여부'">
                  <th v-if="header !== '첨부파일'"> {{ header }} </th>
                  <td v-if="header !== '첨부파일'">
                    <input type="text" class="w100" v-model="formData[header]" :disabled="universityCode === 'entire' || header == '컨소시엄명' || !isMine" 
                    :placeholder="header == '대학명' ? '예) 국민대학교' : 
                               (['운영건수', '건수', '이수자수', '학생 수', '참여교원(명)', '참가자 수', 
                                '이공계', '비이공계', '소계', '설문참여대상자(명)', '설문참여자(명)', '만족도평균(점)',
                                '활용실적(건)', '활용실적(명)', '활용인원(명)', '대상자', '수혜교원(명)', '수혜율(%)'].includes(header) ? '숫자만 입력하세요. 예) 1' : 
                                ['운영일시', '운영일자', '일시'].includes(header) ? '예) 2024-01-01' : '')">
                  </td>
                </tr>
                <!-- 학기 선택 드롭박스로 수정 -->
                <tr v-else-if="header == '학기' || header == '운영학기'">
                  <th>{{ header }}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode == 'entire' || !isMine">
                      <option value="1학기">1학기</option>
                      <option value="하계">하계</option>
                      <option value="2학기">2학기</option>
                      <option value="동계">동계</option>
                    </select>
                  </td>
                </tr>

                <!-- 구분 수정 -->
                <tr v-else-if="header == '구분'">
                  <th>{{ header }}</th>
                  <td>
                    <select v-if="perfGubun != '7' && perfGubun != '8' && perfGubun != '9' && perfGubun != '17'" v-model="formData[header]" :disabled="universityCode=='entire'  || !isMine">
                      <option value="초급">초급</option>
                      <option value="중급">중급</option>
                      <option value="고급">고급</option>
                    </select>
                    <input v-else-if="perfGubun == '17'" v-model="formData[header]" />
                    <input v-else v-model="formData[header]" disabled />
                  </td>
                </tr>

                <!-- 개발주체 수정 -->
                <tr v-else-if="header == '개발주체' || header == '참여형태'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option v-if ="header == '개발주체' || header == '참여형태'" value="공동">공동</option>
                      <!-- <option v-if ="header == '개발주체'" value="공동">공동</option> -->
                      <!-- <option v-if ="header == '참여형태'" value="공통"> 공통</option> -->
                      <option value="단일">단일</option>
                    </select>
                  </td>
                </tr>

                <!-- 교원확보수 관련 (전임/비전임) , 채용형태 수정 -->
                 <!-- 전임비전임일때는 disabled가 먹는디 채용형태일때는 disabled가 안먹음 ㅎ.. -->
                 <tr v-else-if="header == '전임/비전임' || header == '채용형태'">
                  <th>{{header}}</th>
                  <td v-if="perfGubun == '13'">
                    <input type="text" v-model="formData[header]" :disabled="true" />
                  </td>
                  <td v-else>
                    <input type="text" v-model="formData[header]" :disabled="true" />
                  </td>
                 </tr>

                <!-- 활용형태 관련 -->
                <tr v-else-if="header == '활용형태'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode == 'entire' || !isMine">
                      <option value="대학 내부">대학 내부</option>
                      <option value="컨소시엄 내부">컨소시엄 내부</option>
                      <option value="컨소시엄 외부">컨소시엄 외부</option>
                    </select>
                  </td>
                </tr>

                <tr v-else-if="header == '세부내용' || header == '강좌소개'">
                  <th> {{ header }} </th>
                  <td>
                    <textarea class="w100" v-model="formData[header]" :placeholder="header=='세부내용' ? '세부 내용을 입력하세요(선택사항)' : '강좌 소개를 입력하세요 (선택사항)'"></textarea>
                    <!-- <textarea class="w100" v-model="formData[header]" placeholder="세부내용을 입력하세요(선택사항)"></textarea> -->
                    
                  </td>
                </tr>

                <!-- kmooc sub header 세팅 -->
                 <tr v-else-if="header == '강좌수준'">
                  <th> {{ header }}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode == 'entire' || !isMine">
                      <option value="교양">교양</option>
                      <option value="전공기초">전공기초</option>
                      <option value="전공심화">전공심화</option>
                    </select>
                  </td>
                 </tr>

                 <tr v-else-if="header == '개발시기' || header == '자체평가 진행시기' || header == '품질검수 진행시기' || header == '운영시기'">
                  <th> {{header}} </th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                    </select>
                  </td>
                 </tr>

                 <tr v-else-if="header == '자체평가여부' || header == '품질검수여부'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option value="예정">예정</option>
                      <option value="진행">진행</option>
                      <option value="완료">완료</option>
                    </select>
                  </td>
                 </tr>

                 <tr v-else-if="header == '운영여부'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option value="운영예정">운영예정</option>
                      <option value="운영중">운영중</option>
                      <option value="운영완료">운영완료</option>
                    </select>
                  </td>
                 </tr>

                 <tr v-else-if="header == '개발여부'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option value="개발예정">개발예정</option>
                      <option value="개발중">개발중</option>
                      <option value="개발완료">개발완료</option>
                    </select>
                  </td>
                 </tr>
                 <tr v-else-if="header == '학점연계여부'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option value="연계">연계</option>
                      <option value="미연계">미연계</option>
                    </select>
                  </td>
                 </tr>
                 <tr v-else-if="header == '이수증발급여부'">
                  <th>{{header}}</th>
                  <td>
                    <select v-model="formData[header]" :disabled="universityCode =='entire' || !isMine">
                      <option value="발급">발급</option>
                      <option value="미발급">미발급</option>
                    </select>
                  </td>
                 </tr>

                <template v-else-if="header == '대학명'">
                  <tr>
                    <th>{{header}}</th>
                      <td>
                        <select v-if="formData[header] !== '' && formData[header] !== undefined && formData[header] !== null" v-model="formData[header]" :disabled="universityCode==='entire' || !isMine" class="w100">
                          <option v-if="universityName === '국민대학교'" value="국민대학교">국민대학교 </option>
                          <option v-if="universityName === '국민대학교'" value="서울시립대학교">서울시립대학교</option>
                          <option v-if="universityName === '국민대학교'" value="울산과학대학교">울산과학대학교</option>
                          <option v-if="universityName === '국민대학교'" value="전남대학교">전남대학교</option>
                          <option v-if="universityName === '국민대학교'" value="한국항공대학교">한국항공대학교</option>
                          <option v-else-if="universityName === '서울시립대학교'" value="서울시립대학교">서울시립대학교</option>
                          <option v-else-if="universityName === '울산과학대학교'" value="울산과학대학교">울산과학대학교</option>
                          <option v-else-if="universityName === '전남대학교'" value="전남대학교">전남대학교</option>
                          <option v-else-if="universityName === '한국항공대학교'" value="한국항공대학교">한국항공대학교</option>
                        </select>
                      </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="sub in subHeaders" :key="sub">
                    <th v-if="header !== '첨부파일' && sub !== '합계'"> {{ sub }} </th>
                    <td v-if="header !== '첨부파일' && sub !== '합계'">
                      <input type="text" class="w100" v-model="formData[sub]" :disabled="header == '컨소시엄명' || !isMine">
                    </td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
          
          <!-- 파일 드래그 앤 드롭으로 수정한 거 반영해줘야함, -->
          <div class="mgt20">
            <div @dragover.prevent @drop.prevent="handleDrop" class="drop-area">
              <p v-if="files.length === 0" class="cover tc">
                <span class="btn-upload inline btn add-btn">
                  <span class="material-symbols-outlined">upload_file</span>
                  파일을 마우스로 끌어 오세요                  
                </span>
              </p>
              <ul v-if="files!=null && files!=undefined && files.length > 0" class="file-list">
                <li v-for="(file, index) in files" :key="index">                  
                  <p >
                  <!-- <p @click.prevent="downloadFile(file.originalFileName, file.alterFileName)"> -->
                    <!-- a태그 추가 : 파일 다운로드용 -->
                    <!-- <a href="javascript:void(0)" class="block" @click.prevent="downloadFile(file.originalFileName, file.alterFileName)"> -->
                      <!-- {{ file.name }} -->
                        {{ file.originalFileName }}
                    <!-- </a> -->
                  </p>
                  <button type="button" class="file-del" @click.prevent="removeFile(file.fileId, file.originalFileName)" :disabled="!isMine">
                    <span class="material-symbols-outlined">delete</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <!-- // 첨부파일 있는 경우에는 경로도 가져와줘야함 => 다운로드 -->
          <!-- <div class="mgt20">
            <div class="filePicker tr" v-if="universityCode!=='entire'">
              <label>
                <span class="btn-upload inline btn add-btn">
                  파일 업로드
                  <span class="material-symbols-outlined">upload_file22222</span>
                </span>
                <input type="file" multiple id="file" @change="handleFileChange" ref="upload">
              </label>
            </div> 
            <table class="w100 mgt5" v-if="files != null && files != undefined && files.length > 0">
              <colgroup>
                <col style="width:30%">
                <col>
              </colgroup>              
              <tr>
                <th colspan="2">첨부파일</th>
              </tr>
              <tr v-for="(file, i) in files" :key="i">
                <th>파일{{ i+1 }}</th>
                <td>
                  <a href="javascript:void(0)" class="block" @click.prevent="downloadFile(file.alterFileName, file.originalFileName)">
                    {{ file.originalFileName }}
                  </a>
                  <button v-if="universityCode!=='entire'" @click.prevent="removeFile(file.fileId, file.originalFileName)" type="button" class="file-del ty2">
                    <span class="material-symbols-outlined">close</span>
                  </button>
                </td>
              </tr>
            </table>
          </div> -->
        </div>
      </div>   
      <div class="pop-btn tc" v-if="universityCode!=='entire'">
        <button @click="closePopup" type="button">취소</button>
        <button type="button" class="ty3" @click.prevent="saveData">확인</button>
        <!-- <button @click="closePopup" type="button" class="ty3" @click.prevent="saveData">확인</button> -->
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, defineProps, nextTick } from 'vue';
import { useStore } from 'vuex';
import { isPopupOpend, setTableHeaders, getItemValue, getColName, setSubHeader, checkRegx, getUniversityName } from '@/api/setPerf';
import { uploadFileAPI, deleteFileAPI, deletePerfDetailAPI, getCountOfAleadyAddedAPI } from '@/api'
// import { uploadFileAPI, deleteFileAPI, downloadFileAPI, deletePerfDetailAPI } from '@/api'
import { useRoute } from 'vue-router';
import { useCookies } from 'vue3-cookies';
import _ from 'lodash'

const props = defineProps({
	popupId: String
});
const { cookies } = useCookies();
const route = useRoute();
const userInfo = ref(cookies.get("userInfo"));

const store = useStore();
const isTransitioning = ref(false);
const popupData = computed(() => store.getters.getPopupData || {})
const perfId = computed(() => popupData.value.perfId || null);

// 페이지 출력용 formData
const formData = reactive({})
// request시 전달할 데이터 requestData
const requestData = reactive({})
const isDoubleDepth = ref(false)

const tableHeaders = ref([]);
const subHeaders = ref([]);

// advEduDevId 컬럼 값을 가져오기 위한 것이다 -> 테이블의 seqc
const pathId = ref('');
const uriId = computed(() => store.getters.getVariables['uriId']);

const isOlder = ref(false)

const files = ref([]);
const removeFiles = ref([]);
// const upload = ref(null);
const universityCode = ref(route.params.university);
const universityName = ref('');
const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']

const isMine = computed(() => (userInfo.value.university === getUniversityName(universityCode.value)) ? true : false)

const isPopupOpen = computed (() => {
	return store.getters.getPopupById(props.popupId) !== undefined;
})

const closePopup = () => {
	isTransitioning.value = true;
  isPopupOpend.value = false;
  isMine.value = false;
  initializeFormData();
	setTimeout(() => {
    isTransitioning.value = false;
		store.dispatch('closePopup', props.popupId);
	}, 200);
}

const initializeFormData = () => {
  // pathId.value 초기화가 안됨 -> 팝업꺼지면 초기화 하도록 코드 추가
  pathId.value = ''

  tableHeaders.value.forEach(header => {
    formData[header] = '';  // 기본값으로 초기화
  });

  if (isDoubleDepth.value) {
    subHeaders.value.forEach(sub => {
      formData[sub] = '';  // 기본값으로 초기화
    });
  }

  // file 객체도 초기화
  if (files.value.length > 0) {
    files.value = []
  }

  // requestData도 초기화
  for (const key in requestData) {
    // 수정된 코드
    if (Object.prototype.hasOwnProperty.call(requestData, key)) {
      delete requestData[key];
    }
  }
}

const perfGubun = computed(() => {
  let value = store.getters.getVariables['perfGubun'];
  return value;
});

const newFiles = ref([])

// 드래그 앤 드롭 반영해줘야함
const handleDrop = (event) => {
	const droppedFiles = event.dataTransfer.files;
	for (let i =0; i<droppedFiles.length; i++){
		const existingFile = files.value.find(file => file.originalFileName == droppedFiles[i].name)
		if (!existingFile) {
			files.value.push({
			"file": droppedFiles[i],
			"originalFileName" : droppedFiles[i].name
			})

      newFiles.value.push({
        "file": droppedFiles[i],
        "originalFileName" : droppedFiles[i].name
      })
		}
	}
}

// 삭제
const removeFile = (fileId, originalFileName) => {
  // (fileId, originalFileName)
  if (fileId == undefined) {
    return files.value = files.value.filter(file => file.originalFileName !== originalFileName);
  } else if (fileId != undefined && originalFileName != undefined) {
    files.value = files.value.filter(file => file.fileId !== fileId);
    return removeFiles.value.push({
      "fileId": fileId,
      "originalFileName": originalFileName
    });
  }
}

const saveData = async () => {
  if (perfGubun.value === '5' || perfGubun.value === '6')  {
    formData['합계'] = Number(formData['이공계']) + Number(formData['비이공계'])
  }
  if(!checkRegx(formData)) {
    return;
  }

  // adv_edu_share 공동교과 > univ 세팅필요 
  if (perfGubun.value == 10){
    requestData['univ'] = userInfo.value.university
  }

  try {
    await nextTick();
    // 업데이트
    if (pathId.value != null && pathId.value != undefined && pathId.value != '') {
      store.dispatch('updatePopupData', {
        uriPath: store.getters.getVariables['uriPath'], 
        uriId: store.getters.getVariables['uriId'], 
        data: requestData
      }).then((response)=>{
        if (response.status == '200'){
          closePopup();
          if (removeFiles.value.length > 0) {
            _.forEach(removeFiles.value, async (value) => {
              await deleteFileAPI(value.fileId).catch(error => {
                console.log(error.response)
              })
            })
          }

          if (newFiles.value.length === 0) {
            return store.dispatch('openPopup', {id: 'poopupUseTitlePerf', data:`/lms/performance/detail/${universityCode.value}/${perfGubun.value}` })
          } else {
            const fileData = new FormData();

            newFiles.value.forEach(fileWrapper => {
              fileData.append('files', fileWrapper.file); // 실제 파일 객체를 추가
            });
            fileData.append('seqc', store.getters.getVariables['uriId']); // 테이블 seqc
            fileData.append('referenceTable', store.getters.getVariables['uriPath']); // 참조테이블

            uploadFileAPI(fileData).then((response) => {
              if (response.status == 200) {
                return store.dispatch('openPopup', {id: 'poopupUseTitlePerf', data:`/lms/performance/detail/${universityCode.value}/${perfGubun.value}` })
              }
              
            }).catch(error => {
              console.log('error 발생 => ', error.response)
              if (error.response) {
                  console.error('Server Error Message: ', error.response.data);
              }
            })
          }
        } 
      }).catch((error) => {
        console.log(error);
        store.dispatch('openPopup', {id: 'popupUseTitle', data:'저장에 실패했습니다', gubun:'title'});
      })
    } else {
      // 신규등록
      requestData['perfId'] = perfGubun.value
      
      // 2, 4번 항목에 대해서 연도/교과목명이 동일한 게 등록된 게 있으면 등록못하게 해줘야된다고~
      if (perfGubun.value == 2 || perfGubun.value == 4) {
          let requestTable = perfGubun.value == 2 ? 'advEduDev' : 'advEduOpr';
          const response = await getCountOfAleadyAddedAPI(requestTable, requestData)
          if (response.data > 0){
            // return store.dispatch('openPopup', {id: 'popupUseTitle', data:'이미 등록된 과목입니다', gubun: 'title'})
            return alert('이미 등록된 과목입니다')
          }
      }

      store.dispatch('savePopupData', {
        uriPath: store.getters.getVariables['uriPath'], 
        data: requestData 
      }).then((response) => {
        if (response.status == '200' || response.status == 200) {
          closePopup();
          if (newFiles.value.length === 0){
            return store.dispatch('openPopup', {id: 'poopupUseTitlePerf', data:`/lms/performance/detail/${universityCode.value}/${perfGubun.value}` })
          } else {
            const fileData = new FormData();
            let dataId = response.data;

            newFiles.value.forEach(fileWrapper => {
              fileData.append('files', fileWrapper.file); // 실제 파일 객체를 추가
            });
            
            fileData.append('seqc', dataId) // 테이블 seqc
            fileData.append('referenceTable', store.getters.getVariables['uriPath']); // 참조테이블

            uploadFileAPI(fileData).then((response) => {
              if (response.status == 200) {
                return store.dispatch('openPopup', {id: 'poopupUseTitlePerf', data:`/lms/performance/detail/${universityCode.value}/${perfGubun.value}` })
              }
            }).catch(error => {
              console.log('error 발생 => ', error.response)
              if (error.response) {
                  console.error('Server Error Message: ', error.response.data);
              }
            })
          }
        } 
      }).catch((error) => {
        console.log('저장 에러 => ', error)
      })
   } 
  } catch (error) {
    console.log('저장 에러 catch => ', error)
  }
}

const fetchPerfData = async () => {
  await store.dispatch('fetchPopupData', {uriPath: store.getters.getVariables['uriPath'], uriId: store.getters.getVariables['uriId']})
    .then(() => {
      if (popupData.value.files != null && popupData.value.files != undefined && popupData.value.files.length > 0){
        files.value = popupData.value.files
      }
      
      pathId.value = popupData.value[store.getters.getVariables['uriPath'].concat('Id')];
  })
}

const deletePerfDetail = () => {
  if(confirm('삭제하시겠습니까? 삭제 후 복원이 불가합니다.')){
    const result = deletePerfDetailAPI(store.getters.getVariables['uriPath'], popupData.value[store.getters.getVariables['uriPath'].concat('Id')])
    if (result) {
      store.dispatch('openPopup', {id: 'poopupUseTitlePerfDelete', data:`/lms/performance/detail/${universityCode.value}/${perfGubun.value}` })
      closePopup()
    }
  }
}

watch(isPopupOpen, async (newBool) => {
  if (newBool) {
    universityCode.value = route.params.university;
    universityName.value = getUniversityName(universityCode.value)
    
    initializeFormData();
    
    tableHeaders.value = setTableHeaders(perfGubun.value || String(perfId.value))

    if (perfId.value == "5" || perfId.value == "6" || perfId.value == "13" || perfId.value == "14" ||
        perfGubun.value == "5"|| perfGubun.value == "6" || perfGubun.value == "13" || perfGubun.value == "14"
    ) {
      isDoubleDepth.value = true;
      subHeaders.value = setSubHeader(perfGubun.value || String(perfId.value));
    } 
    
    if (uriId.value > 0){
      isOlder.value = true;
      await fetchPerfData();
    
    // formData에 세팅 -기존 데이터가 있을 때 (수정)
      tableHeaders.value.forEach(header => {
        formData[header] = getItemValue(popupData.value, header);
      });

      if (isDoubleDepth.value) {
        subHeaders.value.forEach(sub => {
          formData[sub] = getItemValue(popupData.value, sub)
        })
      }
    } else {
      universityName.value = getUniversityName(universityCode.value)
      initializeFormData();

      // formData에 세팅 - 아무 데이터가 없을 때 (신규)
      tableHeaders.value.forEach(header => {
        if (header == '컨소시엄명') {
          formData[header] = '차세대통신'
        } else if (header == '대학명') {
          if (store.getters.getPerfGubun == 20 || store.getters.getPerfGubun == 21) {
            formData[header] = store.getters.getSubTitle['subUniv']
          } else {
            formData[header] = universityName.value;
          }
        } else if (header == '학기' || header == '운영학기'){
          formData[header] = '1학기'
        } else if (header == '구분' && perfGubun.value !== '17') {
          // 구분  > 7, 8, 9 일 때 (careerAch > 취업률, 창업자 수, 진학률로 알아서 나오게하기)
          if (perfGubun.value == '7' || perfGubun.value == '8' || perfGubun.value == '9') {
            formData[header] = perfGubun.value == '7' ? '취업률' : perfGubun.value == '8' ? '창업자 수' : '진학률'
          } else {
            formData[header] = '초급'
          }
        } else if (header == '개발주체' || header == '참여형태') {
        // } else if (header == '개발주체') {
            formData[header] = '공동'
        } else if (header == '전임/비전임') {
            formData[header] = perfGubun.value == '13' ? '전임' : '비전임'
            
            if(subHeaders.value != null && subHeaders.value.length > 0) {
              subHeaders.value.forEach(sub => {
                if(sub == '채용형태') {
                  formData[sub] = perfGubun.value == '13' ? '전임' : '비전임'
                }
              })
            }
        } else if (header == '활용형태') {
          formData[header] = '대학 내부'
      // 여기부터 kmooc sub 강좌구성현황 관련 초기값 세팅
        // } else if (header == '참여형태') { formData[header] = '공통'
        } else if (header == '강좌수준') { formData[header] = '교양'
        } else if (header == '강좌명') { formData[header] = store.getters.getSubTitle['subTitle']
        } else if (header == '개발시기' || header == '자체평가 진행시기' || header == '품질검수 진행시기' || header == '운영시기') { formData[header] = '1월'
        } else if (header == '자체평가여부' || header == '품질검수여부') { formData[header] = '예정'
        } else if (header == '개발여부') { formData[header] = '개발예정'
        } else if (header == '운영여부') { formData[header] = '운영예정'
        } else if (header == '학점연계여부') { formData[header] = '연계'
        } else if (header == '이수증발급여부') { formData[header] = '발급'
        } else {
          formData[header] = null;
        }
      })
    }
  }
}, { immediate: true });

// 값 수정되면 감지하여 requestData 변경
watch(formData, (newFormData) => {
  if (newFormData != null) {
    tableHeaders.value.forEach(header => {
      if (header !== '컨소시엄명') {
        const colname = getColName(header);
        requestData[colname] = newFormData[header];
      } 
    });
    
    if (isDoubleDepth.value) {
      subHeaders.value.forEach(sub => {
        const colname = getColName(sub)
        requestData[colname] = newFormData[sub];
      })
    }
    requestData[store.getters.getVariables['uriPath'].concat('Id')] = popupData.value[store.getters.getVariables['uriPath'].concat('Id')];
    requestData['usersFk'] = userInfo.value.userSeq
  }
}, { immediate: true })

// function downloadFile(alterFileName, originalFileName) {
//   downloadFileAPI(alterFileName, originalFileName);
// }

</script>

<style lang="scss" scoped>
.lms {
  .popup.popup-page {
    .popup-content {
      max-width:45rem;
      .scroll-box {
        max-height: 50rem;
        td {
          padding:.5rem 2rem;
        }
      }
      .drop-area {        
        border:1px solid #ddd; 
        border-radius:1rem;
        .cover {
          padding:2rem 0;
        }
        .file-list {
          padding:1rem;
          li {
            padding:1rem;
            p {
              display:inline;
              font-size: 1.5rem;
            }
            &:hover {
              background: #eaf6fd;
            }
          }          
        }
      }
      .del-btn {
        height: 3rem;
        padding:0 1.6rem;
        color:$wh;
        font-size: 1.6rem;
        line-height: 3rem;
        background: $tm8;
        border-radius: 2rem;
      }
      .add-btn {
        height: 2.6rem;
        padding:0;
        color:$tm8;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.6rem;
        background: none;
        border-radius: .5rem;
        span {          
          font-weight: 300;
        }
      }
      .file-del {
        height: 2.3rem;
        line-height: 2.3rem;
        padding: 0;        
        border:none;
        background: none;
        span {
          font-size: 2rem;
          font-weight: 500;
          color: $tm5;
          line-height: 2.3rem;
        }
      }      
      .pop-btn button {
        width: 10rem;
      }      
    }        
  }  
}
</style>