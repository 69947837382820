import { ref, onBeforeUnmount } from 'vue';

const useMobile = () => {
  const isMobile = ref(false);
  const isTablet = ref(false);

  const handleResize = () => {
    isMobile.value = calculateIsMobile();
    isTablet.value = calculateIsTablet();
  };

  const calculateIsMobile = () => {
    return window.innerWidth <= 719;
  };
  const calculateIsTablet = () => {
    return window.innerWidth <= 959;
  };

  window.addEventListener('resize', handleResize);
  handleResize();

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });

  return {
    isMobile,
    isTablet,
  };
};

export default {
  install: (app) => {
    app.config.globalProperties.$common = {
      useMobile,
    };
  },
};

