<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">정산보고서</p>
        <div class="scroll-box">          
          <table class="w100">
            <caption class="p-main-tit tl">2023년도 기금사업 정산보고서</caption>
            <tr>
              <th>세부사업명</th>
              <td colspan="4">[305] 인공지능학습용데이터구축</td>
            </tr>
            <tr>
              <th>내역사업명</th>
              <td colspan="4">일상생활 작업 및 명령 수행 데이터(미디어그룹사람과숲)</td>
            </tr>
            <tr>
              <th>사업기간</th>
              <td colspan="4">2023-06-01 ~ 2023-11-30</td>
            </tr>
            <tr>
              <th rowspan="2">총사업비<br>(단위 : 백만원)</th>
              <td>합계</td>
              <td>정보통신진흥기금</td>
              <td>자체자금</td>
              <td>기타</td>
            </tr>
            <tr>
              <td>2,250</td>
              <td>1,800</td>
              <td>450</td>
              <td>0</td>
            </tr>
            <tr>
              <th>협약기관</th>
              <td colspan="4"></td>
            </tr>
            <tr>
              <th rowspan="3">총괄책임자</th>
              <td>성명</td>
              <td colspan="3">이준호</td>
            </tr>
            <tr>
              <td>연락처</td>
              <td colspan="3">010-2457-8841</td>
            </tr>
            <tr>
              <td>전자우편</td>
              <td colspan="3">usd132@humanf.co.kr</td>
            </tr>
          </table>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {      
      .scroll-box {
        max-height:56rem;
        table {
          td {padding-left:1rem;}
        }
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>