<template>
  <footer ref="footerRef" class="rel">
    <div class="inner flex__d__m">
      <div class="la flex__c__m">
        <p><img src="@/assets/lms/img/logo_nv.png" alt=""></p>
        <div>
          <address>
            02707 서울특별시 성북구 정릉로 77 <br class="none">국민대학교 산학협력관 214호<br>
            TEL : 02-910-6782~6791 / FAX : 02-910-6780
          </address>
          <p class="mgt10">Copyright ⓒ 2024 COSS. All rights reserved.</p>
        </div>
      </div>
      <div class="ra">
        <ul class="flex__c__t">
          <li><a href="https://www.facebook.com/" target="-blank"><img src="@/assets/lms/img/sns-icon1.png" alt="FaceBook"></a></li>
          <li><a href="https://www.instagram.com/" target="-blank"><img src="@/assets/lms/img/sns-icon2.png" alt="Instargram"></a></li>
          <li><a href="https://www.youtube.com/" target="-blank"><img src="@/assets/lms/img/sns-icon3.png" alt="YouTube"></a></li>
        </ul>
      </div>
      <button type="button" :class="{'show': showButton}" @click="scrollToTop" class="fix top-btn">
        <span class="material-symbols-outlined">arrow_upward</span>
      </button>
    </div>
  </footer>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const showButton = ref(false);
const footerVisible = ref(false);
const footerRef = ref(null);

const handleScroll = () => {
  if (!footerRef.value) return;
  const footerOffsetTop = footerRef.value.offsetTop;
  const scrollPosition = window.scrollY + window.innerHeight;

  showButton.value = window.scrollY > (window.innerHeight / 3);
  footerVisible.value = scrollPosition >= footerOffsetTop;

  if (footerVisible.value) {
    document.querySelector('.top-btn').classList.add('abs');
  } else {
    document.querySelector('.top-btn').classList.remove('abs');
  }
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  handleScroll();
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.lms {
  footer {
    padding:4rem 0;
    font-size: 1.6rem;
    color:#5a5a5a;
    background: #fff;
    border-top: .1rem solid #e8e8e8;
    .inner {
      padding:0 2rem;
      .la {
        gap: 1rem;
        img {
          width:90%;
          max-width: 15rem;
          margin:0 2rem;
        }
        address {
          line-height:2.2rem;
        }
        p {
          font-size: 1.5rem;
          opacity:.8;
        }
      }
      .ra {
        ul {
          gap : 1.6rem;
          img {width:40px;}
        }        
      }
      .top-btn {        
        bottom: 2rem;
        right: 1.5rem;
        width: 5rem;
        height: 5rem;
        color: $tm7;
        border: .1rem solid $cl2;
        border-radius: 50%;
        background: $wh;
        opacity: 0;
        transform: translateY(5rem);        
        z-index: 10;
        @include transition();
        &.show {
          opacity: 1;
          transform: translateY(0);    
          @include transition($time:.5s);
        }
        &.abs {
          position:absolute !important;
          bottom: inherit;
          top: -7rem;
        }
        &:hover {
          box-shadow: .1rem .1rem .5rem .1rem rgba(0,0,0,0.1);
          @include transition();
        }
      }
      @media (max-width: #{$laptop - 1px}) {
         > * {
          width:100%;
         }
         .la {
          > * {
            width:100%;
            text-align:center;            
          }
          div {
            padding:1rem 0 2rem;
          }
         }
         .ra {
          ul {
            img {width:4rem;}
          }        
        }
      }
      @media (max-width: 440px) {
         .la address br {
          display: block;
        }
      }
    }
  }
} 
</style>