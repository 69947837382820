<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">강의자료</p>
        <div class="scroll-box" v-if="popupData[0].data.lectureFiles">
  <ul>
    <li v-for="item in popupData[0].data.lectureFiles" :key="item.fileId">
      <a href="javascript:void(0)" @click="downloadFile(item.alterFileName, item.originalFileName)" class="inline">
        <strong>{{ item.originalFileName }}</strong>
        <span class="material-symbols-outlined">download</span>
      </a>
    </li>
  </ul>
</div>
      </div>   
      <div class="pop-btn tc mgt20">
        <button @click="closePopup" type="button">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance} from 'vue';
import { downloadFileAPI } from '@/api';

export default {
  props: ['popupId', 'popupData'],
  setup() {
    const { useMobile } = getCurrentInstance().appContext.config.globalProperties.$common;
    const { isMobile } = useMobile();
    return {
      isMobile,
    };
  },
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
    downloadFile(alterFileName, originalFileName) {
      downloadFileAPI(alterFileName, originalFileName)
        .catch((error) => {
          console.error('File download failed:', error);
        });
    },
  }
};

</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:46rem;
      ul {
        padding-bottom: 1rem;
        font-size: 2rem; 
        font-weight: 500;
        li {
          padding-bottom:2rem;
          line-height: 1.2;
          a {
            position:relative;
            padding-left:1.7rem;
            strong {            
              font-weight: 500;              
              &:hover {
                color:$tm4;
                text-decoration: underline; 
              }
            }
            span {
              margin-left:.7rem;
              line-height:1;
            }
            &::before {
              content:'';
              display: block;
              position:absolute;
              width: .5rem;
              height: .5rem;
              top:.7rem;
              left:0;
              background: $cl2;
            }
          }
          
        }
      }
    }        
  }  
}
</style>
