<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">예실대비표</p>
        <div class="tr">
          <small class="mgr10 inline">* 최근 합산일 : 2024-02-27</small>
          <a href="/" class="download-btn inline" download="">엑셀다운로드</a>
        </div>
        <div class="scroll-box">          
          <table class="tc">
            <caption class="tr p-sub-tit">단위 : 원</caption>
            <tr>
              <th rowspan="2">비세목</th>
              <th colspan="2">최초협약예산</th>
              <th colspan="2">최종예산(A)</th>
              <th rowspan="2">국고(집행)<br>(현금)</th>
              <th colspan="2">자부당(집행)</th>
              <th colspan="2">지자체(집행)</th>
              <th colspan="2">미지정(집행)</th>
              <th colspan="3">최종예산(B)</th>
              <th colspan="2">집행잔액(C=A-B)</th>
              <th rowspan="2">집행율(%)(B/A)</th>
            </tr>
            <tr>
              <th>현금</th>
              <th>현물</th>
              <th>현물</th>
              <th>현금</th>
              <th>현금</th>
              <th>현물</th>
              <th>현금</th>
              <th>현물</th>
              <th>현금</th>
              <th>현물</th>
              <th>현금</th>
              <th>현물</th>
              <th>합계</th>
              <th>현금</th>
              <th>현물</th>
            </tr>
            <tr>
              <th>인건비</th>
              <td>1,374,682,000</td>
              <td>405,000,000</td>
              <td>1,374,682,000</td>
              <td>405,000,000</td>
              <td>1,359,013,130</td>
              <td>0</td>
              <td>405,500,000</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>1,359,013,130</td>
              <td>405,000,000</td>
              <td>1,764,013,130</td>
              <td>15,293,870</td>
              <td>0</td>
              <td>99.1</td>
            </tr>
            <tr>
              <th>보수</th>
              <td>126,723,000</td>
              <td>405,000,000</td>
              <td>128,523,640</td>
              <td>405,000,000</td>
              <td>128,523,640</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>128,523,640</td>
              <td>405,000,000</td>
              <td>533,523,640</td>
              <td>0</td>
              <td>0</td>
              <td>100</td>
            </tr>
            <tr class="total">
              <th>합계</th>
              <td>1,501,405,000</td>
              <td>810,000,000</td>
              <td>1,502,830,640</td>
              <td>810,000,000,</td>
              <td>1,487,536,770</td>
              <td>0</td>
              <td>405,000,000</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>1,487,536,770</td>
              <td>810,000,000</td>
              <td>2,297,536,770</td>
              <td>15,293,870</td>
              <td>0</td>
              <td>100</td>
            </tr>
          </table>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],  
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:90rem;
      .scroll-box {
        width: auto;
        max-height: inherit;
        padding-right:0;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: auto;
      }
      .download-btn {
        height: 3rem;
        padding:0 1.6rem;
        color:$wh;
        font-size: 1.4rem;
        line-height: 3rem;
        background: $tm9;
        border-radius: .5rem;
      }
      table {
        width:2600px;
      }
      .pop-btn button {
        width: 10rem;
      }
    }        
  }  
}
</style>