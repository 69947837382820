<template>
  <div class="loading fix flex__c__m" :class="{ 'hide': !isLoading }">
    <div class="ring"></div>
  </div>
</template>

<script>
export default { 
  props: ["LoadingValue"],
  data() {
    return {
      isLoading: this.LoadingValue
    };
  },
  updated() {
    if (this.LoadingValue != undefined) {
      this.isLoading = this.LoadingValue;

      setTimeout(() => {
        this.isLoading = false;
        this.$emit('closeLoading', false)
      }, 3500);
    }
  }
};
</script>