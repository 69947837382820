<template>
  <div 
    v-for="popup in openPopups" 
    :key="popup.id" 
    @click="popup.dimClose ? closeOutside(popup.id, $event) : null"
    class="popup fix flex__c__m rel"
  >
    <div :class="{'smooth': isTransitioning}" class="popup-content flex__c__m tc rel" >
      
      <component
        :is="popup.type === 'message' ? PopupMessage : popup.component"
        :popup="popup"
        :openPopup="openPopup"
        :closePopup="closePopup"
      />
      
      <button @click="closePopup(popup.id)" type="button" class="pop-clz abs">
        <img src="@/assets/cms/img/close.svg" alt="">
      </button>

    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import PopupMessage from '@/components/cms/popup/PopupMessage.vue';
import PerfDataAdd from '@/screens/cms/performance/popup/DataAdd.vue';
import PerfDataEdit from '@/screens/cms/performance/popup/DataEdit.vue';
import PerfDataChoice from '@/screens/cms/performance/popup/DataChoice.vue';

const store = useStore();
const isTransitioning = ref(false);

const popups = ref([
  
  /*
    data: {  } 내에 nextPopup: 'nextPopupID' 추가 시 
    확인 버튼을 클릭할 경우, 기존 팝업 닫히고 해당 ID를 가진 팝업이 새롭게 열림
    message Type 팝업이 아닐 경우, 해당 컴포넌트에 openNext() 관련 함수가 있어야 함
  */

  // message Type
  { id: 'Logout', type: 'message', data: { txt1: '로그아웃 되었습니다.' }, dimClose: true },
  { id: 'EditDone', type: 'message', data: { txt1: '수정되었습니다.' }, dimClose: true },
  { id: 'DelDone', type: 'message', data: { txt1: '삭제되었습니다.' }, dimClose: true },
  { id: 'AddDone', type: 'message', data: { txt1: '등록되었습니다.' }, dimClose: true },
  // { id: 'AddDuple', type: 'message', data: { txt1: '이미 등록된 과목입니다.' }, dimClose: true },

  // normal Type
  { id: 'PerfDataAdd', component: PerfDataAdd, dimClose: false },
  { id: 'PerfDataEdit', component: PerfDataEdit, dimClose: false },  
  { id: 'PerfDataChoice', component: PerfDataChoice, dimClose: false },  
  
]);

const openPopups = computed(() => 
  popups.value.filter(popup => store.getters.getPopupById(popup.id))
);

function closePopup(popupId) {
  store.commit('setPopupData', {}); // 팝업을 끄면 store에 저장된 popupData를 비워줘야함
  isTransitioning.value = true;
  setTimeout(() => {
    isTransitioning.value = false;
    store.dispatch('closePopup', popupId);
  }, 200);
}

function closeOutside(popupId, event) {
  if (event.target.classList.contains('popup')) {
    closePopup(popupId);
  }
}
</script>

<style lang="scss" scoped>
.cms {  
  .popup {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3rem;
    background: rgba(0, 0, 0, .5);
    overflow: auto;
    z-index: 1000;
    $scr-color: #a5a5a5;
    scrollbar-color: $scr-color transparent;
    &::-webkit-scrollbar-thumb {
      background-color: $scr-color;      
    }
    .popup-content {      
      min-height: 20rem;      
      padding: 5rem 3rem 4rem;
      background: #fff;
      border-radius: .8rem;
      box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, .1);
      animation: moveUp .3s ease;
      transition: all 0.6s ease;
      &.smooth {
        transform: translateY(5rem);
        opacity: 0;
      }
      .pop-clz {
        $btn-size: 2.4rem;
        width: $btn-size;
        height: $btn-size;
        padding: .1rem;
        top: 1.3rem;
        right: 1.3rem;
        background: #eee;
        border-radius: 50%;
        opacity: .5;
        @include trans();
        img {
          width: 100%;
          height: 100%;          
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  @keyframes moveUp {
    0% {
      transform: translateY(5rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>