<template>
  <div v-if="isPopupOpen" class="popup popup-default fix flex__c__m rel">
    <div class="popup-content flex__c__m tc rel" :class="{'smooth': isTransitioning }">
      <div>
        <p v-if="popupData != undefined && popupData[0] != undefined && popupData[0].data != undefined" class="t1">{{ popupData[0].data }}</p>
        <p v-else-if="popupData.txt1" class="t1">{{ popupData.txt1 }}</p>
        <p v-if="popupData.txt2" class="t2 mgt10">{{ popupData.txt2 }}</p>
      </div>      
      <div class="pop-btn abs felx__c__m">
        <button v-if="popupData.addBtn" @click="closePopup" type="button" class="ty2">취소</button>
        <button v-if="popupData.nextDelOpen" @click="handleConfirmClick(popupLink)" type="button" class="ty3">확인</button>
        <button v-else @click="closePopup(popupLink)" type="button" class="ty3">확인</button>
      </div>
      <button @click="closePopup(popupLink)" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId', 'popupData', 'popupLink'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup(Link) {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
      if (Link != null && Link != undefined && Link != '' && Link.length > 0) {
        if (Link[0].gubun != 'play' && Link[0].gubun != 'title') {
          if (Link[0].data == 'reload') {
            this.$router.go(0);
          } else {
            location.href = Link[0].data
          }
        }
      }
    },
    handleConfirmClick(event) {
      this.$store.dispatch('getResult', { gubun : event[0].gubun, data : true});
      this.closePopup();
    }
  },
};
</script>