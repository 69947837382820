<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m field-pop">    
  <!-- <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m field-pop" @click="closePopupOutside">     -->
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <div class="flex__d__m">
          <p class="t1 tit">강좌선택</p>
        </div>
        <form id="" name="">
          <div class="search-box flex__d__t mgt20">            
            <select v-model="selectedValue">
              <option value="전체">전체</option>
              <!-- <option value="대학명">대학명</option> -->
              <option value="강좌명">강좌명</option>
              <option value="강좌번호">강좌번호</option>
            </select>
            <div class="search-type inline clear">                
              <div class="fl">
                <input type="text" v-model="text" placeholder="검색어를 입력해주세요." title="검색 내용 입력" class="w100">
              </div>
              <button class="inline-t fr" @click.prevent="getAllKmoocSubSearch">
                <span class="material-symbols-outlined">search</span>
              </button>
            </div>
          </div>
        </form>

        <div class="scroll-box">
          <div>
            <table class="w100 tc">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
              </colgroup>              
              <tr>
                <th>순번</th>
                <th>대학명</th>
                <th>강좌명</th>
                <th>강좌번호</th>
              </tr>

              <template v-for="item in subjectList" :key="item">
                <tr @click.prevent="handleRowCreate(item, 'popupPerf')">
                  <td>{{ item.postNumber }} </td>
                  <td>{{ item.univ }} </td>
                  <td>{{ item.subNm }} </td>
                  <td>{{ item.subNmbr }} </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button">취소</button>
        <!-- <button @click="closePopup('popupPerfChoice')" type="button" class="ty3">확인</button> -->
        <!-- <button @click="closePopup('popupPerfChoice');openPopup('popupPerf');" type="button" class="ty3">확인</button> -->
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {getUniversityName} from '@/api/setPerf'

const route = useRoute();
const props = defineProps({
  popupId: String,
});
const isTransitioning = ref(false);
const store = useStore();

const subjectList = computed(() => store.getters.getSubjectList || {})
const selectedValue = ref('전체');
const text = ref('');
const univ = ref('');
const routeUniv = ref('')

const isPopupOpen = computed(() => {
  return store.getters.getPopupById(props.popupId) !== undefined;
});

const closePopup = () => {
  isTransitioning.value = true;
  setTimeout(() => {
    isTransitioning.value = false;
    store.dispatch('closePopup', props.popupId);
    // 검색창 데이터 초기화
    selectedValue.value = '전체'
    text.value = ''
    univ.value = ''
  }, 200);
};

const handleRowCreate = async (item, popId) => {
  store.commit('setSubTitle', {subTitle: item.subNm, subUniv: item.univ})
  store.dispatch('openPopup', {id: popId})
  closePopup()
}

// 검색
const getAllKmoocSubSearch = async () => {
  if (selectedValue.value != '전체' && (text.value == '' || text.value == undefined || text.value == null)) {
    // store.dispatch('openPopup', {id: 'popupUseTitle', data: '검색어를 입력하세요', gubun: 'title'})
    alert('검색어를 입력하세요')
  } else {
    try {
      await store.dispatch('fetchSubjectList', {selectedValue: selectedValue.value, text: text.value, univ: univ.value})
      if (!subjectList.value) {
        return store.dispatch('openPopup', {id: 'popupUseTitle', data: '강의 목록이 없습니다. 강의를 먼저 등록하세요', gubun: 'title'})
      }
    } catch (error) {
      console.error(error)
      store.dispatch('openPopup', {id: 'popupUseTitle', data: '검색 실패했습니다', gubun: 'title'})
    }
  }
}

watch(isPopupOpen, async (newBool) => {
  if(newBool) {
    try {
      routeUniv.value = route.params.university;
      univ.value = getUniversityName(routeUniv.value)

      await store.dispatch('fetchSubjectList', { selectedValue: selectedValue.value, text: '', univ: univ.value })
      if (!subjectList.value) {
        return store.dispatch('openPopup', {id: 'popupUseTitle', data: '강의 목록이 없습니다. 강의를 먼저 등록하세요', gubun: 'title'})
      }
    } catch (error) {
      console.error(error)
      store.dispatch('openPopup', {id: 'popupUseTitle', data: '조회 실패했습니다', gubun: 'title'})
    }
  }
})

</script>


<style lang="scss" scoped>
.lms {
  .popup.popup-page {
    .popup-content {
      max-width:45rem;      
      .search-box {
        $formDH :4.5rem;
        select, input:not([type="radio"]):not([type="checkbox"]), button {
          padding:0 1.5rem;
          height: $formDH;
          line-height: $formDH;
          background-color: $wh;
          border: .1rem solid #eee;
          border-radius: .5rem;
        }
        select {
          position: relative;
          width:10rem;
          background-image:url($url+'arrow.png');
          background-repeat: no-repeat;
          background-position: right 1rem center;          
        }
        .search-type {
          width: calc(100% - 11rem);
          > div {
            width: calc(100% - 6rem);
          }
          button {
            width: 5rem;
            color: $wh;
            border: .1rem solid $tm2;
            background: $tm2;
            line-height: 1;  
          }
        }        
      }
      .scroll-box {
        max-height: 50rem;
        padding-bottom:2rem;
      }
      .del-btn {
        height: 3rem;
        padding:0 1.6rem;
        color:$wh;
        font-size: 1.6rem;
        line-height: 3rem;
        background: $tm8;
        border-radius: 2rem;
      }      
      .pop-btn button {
        width: 10rem;
      }      
    }        
  }  
}
</style>