<template>
  <article>
    <form action="">
      <h1 class="tit">성과 관리</h1>      
      <DataForm ref="formData" @sendFormData="handleFormData" :compType="'modi'"/>
      <!-- <DataForm />       -->
      <div class="pop-btn flex__c__m mgt20">
        <button v-if="isMine" @click.prevent.stop="handleDelete(); openPopup('DelDone')" type="button" class="btn__rev">삭제</button>
        <button v-if="isMine" @click="handleEdit(); openPopup('EditDone')" type="button" class="btn__base"> 수정</button>
        <!-- <button @click="props.closePopup(props.popup.id); openPopup('DelDone')" type="button" class="btn__rev">삭제</button>
        <button @click="props.closePopup(props.popup.id); openPopup('EditDone')" type="button" class="btn__base">수정</button> -->
      </div>
    </form>
  </article>
</template>

<script setup>
import { defineProps, shallowRef, markRaw, nextTick, computed } from 'vue';
import { useStore } from 'vuex';
import DataForm from '@/screens/cms/performance/popup/DataForm.vue';
import { deletePerfDetailAPI, uploadFileAPI, deleteFileAPI } from '@/api'

const store = useStore();
const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
});
const formData = shallowRef(markRaw(DataForm));
const isMine = computed(() => store.getters.getIsMine);

const handleClose = () => {
  props.closePopup(props.popup.id);
}

const handleEdit = () => {
  formData.value.sendFormData();
}

const handleDelete = () => {
  if(confirm('삭제하시겠습니까? 삭제 후 복원이 불가합니다')) {
    deletePerfData();
  }
}

async function handleFormData(formData) {
  try {
    await nextTick()
    await editPerfData(formData);
  } catch (error) {
    console.error(error);
  }
}

// 수정
async function editPerfData(formData){
  try {
    store.dispatch('updatePopupData', {
        uriPath: store.getters.getVariables['uriPath'], 
        uriId: store.getters.getVariables['uriId'], 
        data: formData
      }).then((response)=>{
        if (response.status == '200'){ 
          manageFile(formData);

          handleClose();
          store.commit('setIsUpdated', true);
        }})
  } catch (error) {
    console.error(error);
  }
}

// 삭제
async function deletePerfData() {
  const popupData = store.getters.getPopupData
  try {
    const result = await deletePerfDetailAPI(
      store.getters.getVariables['uriPath'],
      popupData[store.getters.getVariables['uriPath'].concat('Id')]
    )
    if (result.status === 200){
      store.commit('setIsUpdated', true);
      handleClose();
    }
  } catch (error) {
    console.error('삭제할 수 없습니다', error);
    alert('삭제 실패했습니다');
  } 
}

async function manageFile (formData) {
  if (formData['newFiles'].length > 0) {
      const fileData = new FormData();
      formData['newFiles'].forEach(fileWrapper => {
        fileData.append('files', fileWrapper.file);
      })
      fileData.append('seqc', store.getters.getVariables['uriId']);
      fileData.append('referenceTable', store.getters.getVariables['uriPath']);
      await uploadFileAPI(fileData);
    }
    
    if (formData['removeFiles'].length > 0) {
      formData['removeFiles'].forEach(file => {
        deleteFileAPI(file.fileId);
      })
    }
}

</script>