<template>

  <div>
    <mainNav></mainNav>
    <router-view></router-view>
    <mainFooter></mainFooter>
    <TopBar></TopBar>

  </div>

</template>

<script>
import mainNav from "@/components/main/common/NavComponents.vue";
import mainFooter from "@/components/main/common/FooterComponents.vue";
import TopBar from "@/components/main/home/component/TopBar.vue";

export default {
  components: {
    mainNav,mainFooter, TopBar
  }
}

</script>

<style scoped lang="scss">

</style>