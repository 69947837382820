<template>
  <article>
    <div>
      <p v-if="props.popup.data.txt1" class="t1">{{ props.popup.data.txt1 }}</p>
      <p v-if="props.popup.data.txt2" class="t2 mgt10">{{ props.popup.data.txt2 }}</p>
    </div>
    <div class="pop-btn flex__c__m mgt20">
      <button 
        v-if="props.popup.data.cancel" 
        @click="props.closePopup(props.popup.id)" 
        type="button" 
        class="btn__wh">
        취소
      </button>
      <button 
        @click="props.popup.data.nextPopup ? openNext() : props.closePopup(props.popup.id)" 
        type="button" 
        class="btn__bk">
        확인
      </button>
    </div>
  </article>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
  openPopup: { type: Function, required: true }
});

function openNext() {
  props.closePopup(props.popup.id);
  props.openPopup(props.popup.data.nextPopup);
}
</script>

<style lang="scss" scoped>
.cms .popup {
  article {
    width: auto;
    min-width: 24rem;
    max-width: 30rem;
    p {
      color: $bk;
      line-height:1.2;
      &.t1 {
        font-size: 1.8rem;
        font-weight:600;
      }
      &.t2 {
        opacity: .8;
      }
    }  
  }
}
</style>