<template>
  <div>

    <router-view />



  </div>
</template>


<script>


export default {
  name: 'SubPage',
  components: {},
  data() {},
}

</script>

<style scoped>



</style>